import React, { useState } from "react";
import LeftArrow from "../../../assets/icons/arrow-left.svg"; // Path to your left arrow icon
import RightArrow from "../../../assets/icons/arrow-right.svg";
import { Form, Button } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";

const tickets = [
  {
    id: "1234567890",
    name: "Baraka M.Jaa",
    department: "Billing",
    subject: "Help with billing Help with billing Help with billing ...",
    date: "23-12-2024   4:35 PM",
  },
  {
    id: "1234567890",
    name: "John",
    department: "Billing",
    subject: "Help with billing Help with billing Help with billing ...",
    date: "23-12-2024   4:35 PM",
  },
  {
    id: "1234567890",
    name: "Salma Godwin",
    department: "Drivers",
    subject: "Help with billing Help with billing Help with billing ...",
    date: "23-12-2024   4:35 PM",
  },
  {
    id: "1234567890",
    name: "Abdul",
    department: "Billing",
    subject: "Help with billing Help with billing Help with billing ...",
    date: "23-12-2024   4:35 PM",
  },
  {
    id: "1234567890",
    name: "Joshua Mick",
    department: "Billing",
    subject: "Help with billing Help with billing Help with billing ...",
    date: "23-12-2024   4:35 PM",
  },
  {
    id: "1234567890",
    name: "Christina",
    department: "Drivers",
    subject: "Help with billing Help with billing Help with billing ...",
    date: "23-12-2024   4:35 PM",
  },
  {
    id: "1234567890",
    name: "Christina",
    department: "Billing",
    subject: "Help with billing Help with billing Help with billing ...",
    date: "23-12-2024   4:35 PM",
  },
  {
    id: "1234567890",
    name: "Christina",
    department: "Billing",
    subject: "Help with billing Help with billing Help with billing ...",
    date: "23-12-2024   4:35 PM",
  },
  {
    id: "1234567890",
    name: "Christina",
    department: "Billing",
    subject: "Help with billing Help with billing Help with billing ...",
    date: "23-12-2024   4:35 PM",
  },
  {
    id: "1234567890",
    name: "Christina",
    department: "Billing",
    subject: "Help with billing Help with billing Help with billing ...",
    date: "23-12-2024   4:35 PM",
  },
];

const SupportTicket: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [activationStatus, setActivationStatus] = useState("Activated");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  // const filteredUsers = tickets.filter(
  //   (ticket) =>
  //     ticket.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //     ticket.department.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //     ticket.subject.includes(searchQuery)
  // );

  return (
    <div
      className="p-6 bg-white min-h-screen"
      style={{ maxWidth: "100%", marginLeft: "250px", marginTop: "100px" }}
    >
      <Box sx={{ p: 3.5, bgcolor: "background.paper" }} color="#2C3E50">
      <div className="flex justify-between items-center mb-4">
          <Typography variant="h4" gutterBottom color="#2C3E50">
            Support Tickets
          </Typography>
          <div className="flex items-center gap-4">
            <Select
              value={activationStatus}
              onChange={(e) => setActivationStatus(e.target.value as string)}
              sx={{
                width: 170,
                height: "3rem",
                borderRadius: "10px",
                "& fieldset": {
                  borderRadius: "10px",
                },
              }}
            >
              <MenuItem value="Activated">Activated</MenuItem>
              <MenuItem value="Panding Review">Pending Review</MenuItem>
            </Select>
          
          <div className="relative ">
            <Search
              className="absolute left-4 top-1/2 transform -translate-y-1/2"
              size={20}
              style={{ color: "#FDA214" }} // Change icon color
            />
            <Form.Control
              type="text"
              placeholder="Search Drivers by Name"
              className="pl-10 pr-6 py-2 w-112 rounded-full border border-gray-300"
              value={searchQuery}
              onChange={handleSearch}
              style={{
                width: "30rem",
                height: "3.5rem",
                lineHeight: "3rem",
                borderRadius: "20px",
                textAlign: "center",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }} // Custom width and height
            />
          </div>
        </div>
        </div>
        <TableContainer component={Paper} elevation={0} >
          <Table>
            <TableHead sx={{backgroundColor: '#F0F3FA', len: '%'}}>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', padding: '16px',  }}>Ticket No.</TableCell>
        <TableCell sx={{ fontWeight: 'bold', padding: '16px',  }}>First Name</TableCell>
        <TableCell sx={{ fontWeight: 'bold', padding: '16px',  }}>Department</TableCell>
        <TableCell sx={{ fontWeight: 'bold', padding: '16px',  }}>Subject</TableCell>
        <TableCell align="right" sx={{ fontWeight: 'bold', padding: '16px', }}>
          Date Created <KeyboardArrowDown />
        </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tickets.map((ticket) => (
                <TableRow key={ticket.id}>
                  <TableCell>{ticket.id}</TableCell>
                  <TableCell>{ticket.name}</TableCell>
                  <TableCell>{ticket.department}</TableCell>
                  <TableCell>{ticket.subject}</TableCell>
                  <TableCell align="right" sx={{ padding: "40px" }}>
                    {ticket.date}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          className="d-flex justify-content-center  align-items-center mt-4 flex-wrap p-3 rounded-lg"
          style={{ height: "100px" }}
        >
          <div className="d-flex justify-content-center mt-auto flex-wrap">
            {/* Previous Button with Custom Left Arrow */}
            <Button
              variant={currentPage > 1 ? "outline-secondary" : "disabled"}
              size="sm"
              className="mx-1 d-flex align-items-center justify-content-center"
              onClick={() =>
                currentPage > 1 && handlePageChange(currentPage - 1)
              }
              style={{
                backgroundColor: currentPage > 1 ? "#FDA214" : "transparent",
                color: currentPage > 1 ? "#fff" : "#6c757d",
                borderColor: currentPage > 1 ? "#FDA214" : "#6c757d",
              }}
            >
              <img src={LeftArrow} alt="Previous" className="mr-2" width="16" />{" "}
              Previous
            </Button>

            {/* Page Numbers */}
            {[1, 2, 3, "...", 8, 9, 10].map((page, index) => (
              <Button
                key={index}
                variant={currentPage === page ? "primary" : "outline-secondary"}
                size="sm"
                onClick={() =>
                  typeof page === "number" && handlePageChange(page)
                }
                className={`mx-1 ${page === "..." ? "cursor-default" : ""}`}
                style={{
                  backgroundColor:
                    currentPage === page ? "#FDA214" : "transparent",
                  color: currentPage === page ? "#fff" : "#FDA214",
                  borderColor: currentPage === page ? "#FDA214" : "#FDA214",
                }}
                disabled={page === "..."}
              >
                {page}
              </Button>
            ))}

            {/* Next Button with Custom Right Arrow */}
            <Button
              variant={currentPage < 10 ? "outline-secondary" : "disabled"}
              size="sm"
              className="mx-1 d-flex align-items-center justify-content-center"
              onClick={() =>
                currentPage < 10 && handlePageChange(currentPage + 1)
              }
              style={{
                backgroundColor: currentPage < 10 ? "#FDA214" : "transparent",
                color: currentPage < 10 ? "#fff" : "#6c757d",
                borderColor: currentPage < 10 ? "#FDA214" : "#6c757d",
              }}
            >
              Next{" "}
              <img src={RightArrow} alt="Next" className="ml-2" width="16" />
            </Button>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default SupportTicket;
