import React, {  useState } from "react";
import { NavLink, } from "react-router-dom";
import DashboardIcon from "../../assets/icons/Deshboard.svg";
import LogoutIcon from "../../assets/icons/ic_outline-logout.svg";
import UserIcon from "../../assets/icons/heroicons_users.svg";
import DriversIcon from "../../assets/icons/clarity_employee-line.svg";
import SubscriptionsIcon from "../../assets/icons/ph_coin.svg";
import SupportTicketsIcon from "../../assets/icons/solar_help-broken.svg";
import DriverPayoutsIcon from "../../assets/icons/Frame.svg";
import ContactDetailsIcon from "../../assets/icons/mage_id-card.svg";

const Sidebar: React.FC = () => {
 
  const [activeItem, setActiveItem] = useState("Dashboard"); // State to track active item
  const handleSetActive = (item: string) => {
    setActiveItem(item);
  };

  return (
    <div className="fixed w-[260px] h-[calc(100vh-50px)] left-0 top-[60px]  bg-[#2C3E50] flex flex-col justify-between pl-0 pr-[20px] pt-[20px] pb-[20px] text-white">

      <div className="mb-9 text-xl font-bold cursor-pointer">
        {/* NavLink for Dashboard */}
        <NavLink
          to="dashboard"
          className={({ isActive }) =>
            `flex items-center py-4 border-none shadow-none transition-all duration-300 ${
              isActive || activeItem === "dashboard" ? "bg-[#FDA214]" : ""
            }`
          }
          style={{ padding: "40px ", width: '108.2%', outline: 'none',textDecoration: 'none', border: 'none', boxShadow: 'none' }}
          onClick={() => handleSetActive("dashboard")}
        >
          <img
            src={DashboardIcon}
            alt="Dashboard"
            className={`w-[18px] h-[18px] mr-[20px] ${
              activeItem === "dashboard" ? "filter brightness-0 invert" : "text-orange-400"
            }`}
          />
          <span
            className={`text-sm font-medium ${
              activeItem === "dashboard" ? "text-white" : "text-[#FDA214]"
            }`}
          >
            DASHBOARD
          </span>
        </NavLink>

        {/* NavLink for Users */}
        <NavLink
          to="users"
          className={({ isActive }) =>
            `flex items-center py-4 transition-all duration-300 ${
              isActive || activeItem === "users" ? "bg-[#FDA214]" : ""
            }`
          }
          style={{ padding: '40px', width: '108.2%', outline: 'none' ,textDecoration: 'none'}}
          onClick={() => handleSetActive("users")}
        >
          <img
            src={UserIcon}
            alt="Users"
            className={`w-[18px] h-[18px] mr-[20px] ${
              activeItem === "users" ? "filter brightness-0 invert" : "text-orange-400"
            }`}
          />
          <span
            className={`text-sm font-medium ${
              activeItem === "users" ? "text-white" : "text-[#FDA214]"
            }`}
          >
            USERS
          </span>
        </NavLink>

        {/* NavLink for Drivers */}
        <NavLink
          to="drivers"
          className={({ isActive }) =>
            `flex items-center py-4 transition-all duration-300 ${
              isActive || activeItem === "drivers" ? "bg-[#FDA214]" : ""
            }`
          }
          style={{ padding: '40px', width: '108.2%', outline: 'none',textDecoration: 'none' }}
          onClick={() => handleSetActive("drivers")}
        >
          <img
            src={DriversIcon}
            alt="Drivers"
            className={`w-[18px] h-[18px] mr-[20px] ${
              activeItem === "drivers" ? "filter brightness-0 invert" : "text-orange-400"
            }`}
          />
          <span
            className={`text-sm font-medium ${
              activeItem === "drivers" ? "text-white" : "text-[#FDA214]"
            }`}
          >
            DRIVERS
          </span>
        </NavLink>

        {/* NavLink for Subscriptions */}
        <NavLink
          to="subscriptions"
          className={({ isActive }) =>
            `flex items-center py-4 transition-all duration-300 ${
              isActive || activeItem === "subscriptions" ? "bg-[#FDA214]" : ""
            }`
          }
          style={{ padding: '40px ', width: '108.2%', outline: 'none',textDecoration: 'none' }}
          onClick={() => handleSetActive("subscriptions")}
        >
          <img
            src={SubscriptionsIcon}
            alt="Subscriptions"
            className={`w-[18px] h-[18px] mr-[20px] ${
              activeItem === "subscriptions" ? "filter brightness-0 invert" : "text-orange-400"
            }`}
          />
          <span
            className={`text-sm font-medium ${
              activeItem === "subscriptions" ? "text-white" : "text-[#FDA214]"
            }`}
          >
            SUBSCRIPTIONS
          </span>
        </NavLink>

        {/* NavLink for Support Tickets */}
        <NavLink
          to="support-tickets"
          className={({ isActive }) =>
            `flex items-center py-4 transition-all duration-300 ${
              isActive || activeItem === "support-tickets" ? "bg-[#FDA214]" : ""
            }`
          }
          style={{ padding: '40px ', width: '108.2%', outline: 'none',textDecoration: 'none' }}
          onClick={() => handleSetActive("support-tickets")}
        >
          <img
            src={SupportTicketsIcon}
            alt="Support Tickets"
            className={`w-[18px] h-[18px] mr-[20px] ${
              activeItem === "support-tickets" ? "filter brightness-0 invert" : "text-orange-400"
            }`}
          />
          <span
            className={`text-sm font-medium ${
              activeItem === "support-tickets" ? "text-white" : "text-[#FDA214]"
            }`}
          >
            SUPPORT TICKETS
          </span>
        </NavLink>

        {/* NavLink for Driver Payouts */}
        <NavLink
          to="driver-payouts"
          className={({ isActive }) =>
            `flex items-center py-4 transition-all duration-300 ${
              isActive || activeItem === "driver-payouts" ? "bg-[#FDA214]" : ""
            }`
          }
          style={{ padding: '40px ', width: '108.2%', outline: 'none',textDecoration: 'none'}}
          onClick={() => handleSetActive("driver-payouts")}
        >
          <img
            src={DriverPayoutsIcon}
            alt="Driver Payouts"
            className={`w-[18px] h-[18px] mr-[20px] ${
              activeItem === "driver-payouts" ? "filter brightness-0 invert" : "text-orange-400"
            }`}
          />
          <span
            className={`text-sm font-medium ${
              activeItem === "driver-payouts" ? "text-white" : "text-[#FDA214]"
            }`}
          >
            DRIVER PAYOUTS
          </span>
        </NavLink>

        {/* NavLink for Contact Details */}
        <NavLink
          to="contact-details"
          className={({ isActive }) =>
            `flex items-center py-4 transition-all duration-300 ${
              isActive || activeItem === "contact-details" ? "bg-[#FDA214]" : ""
            }`
          }
          style={{ padding: '40px ', width: '108.2%', outline: 'none',textDecoration: 'none' }}
          onClick={() => handleSetActive("contact-details")}
        >
          <img
            src={ContactDetailsIcon}
            alt="Contact Details"
            className={`w-[18px] h-[18px] mr-[20px] ${
              activeItem === "contact-details" ? "filter brightness-0 invert" : "text-orange-400"
            }`}
          />
          <span
            className={`text-sm font-medium ${
              activeItem === "contact-details" ? "text-white" : "text-[#FDA214]"
            }`}
          >
            CONTACT DETAILS
          </span>
        </NavLink>
      </div>

      <div className="px-4 py-4">
        <button
          className="flex items-center justify-center w-full bg-red-600 text-white py-2 px-4 rounded"
          onClick={() => {
            // Log out logic here
          }}
        >
          <img src={LogoutIcon} alt="Logout" className="mr-4 w-5 h-5" />
          <span>Log Out</span>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
