import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PersonDash from "../../../../assets/icons/flowbite_user-remove-outline.svg";
import GreenIcon from "../../../../assets/icons/Greeen.svg";
import LeftArrow from "../../../../assets/icons/arrow-left.svg"; // Path to your left arrow icon
import RightArrow from "../../../../assets/icons/arrow-right.svg";
import { Form, Button} from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";

// Example driver data
const drivers: Driver[] = [
  {
    id: 1,
    name: "Baraka M.Jaa",
    phone: "+255 754 805 256",
    email: "emailaddressgfsdf@gmail.com",
    dateJoined: "23-12-2024 4:35 PM",
    vehicleType: "Toyota Yaris",
    vehicleColor: "Blue",
    AccountType: 'Company Account',
    CompanyName: 'General trucks Ltd',
    licensePlateNumber: "1234AB",
    subscriptionStatus: "ACTIVE",
    subscriptionStartDate: "12 AUG 2024",
    subscriptionEndDate: "12 SEP 2025",
  },
  {
    id: 2,
    name: "John",
    phone: "+255 754 805 256",
    email: "emailaddres@gmail.com",
    dateJoined: "23-12-2024 4:35 PM",
    vehicleType: "Toyota Yaris",
    vehicleColor: "Blue",
    AccountType: 'Company Account',
    CompanyName: 'General trucks Ltd',
    licensePlateNumber: "1234AB",
    subscriptionStatus: "ACTIVE",
    subscriptionStartDate: "12 AUG 2024",
    subscriptionEndDate: "12 SEP 2025",
  },
  {
    id: 3,
    name: "Salma Godwin",
    phone: "+255 754 805 256",
    email: "emailaddresdf@gmail.com",
    dateJoined: "23-12-2024 4:35 PM",
    vehicleType: "Toyota Yaris",
    vehicleColor: "Blue",
    AccountType: 'Company Account',
    CompanyName: 'General trucks Ltd',
    licensePlateNumber: "1234AB",
    subscriptionStatus: "ACTIVE",
    subscriptionStartDate: "12 AUG 2024",
    subscriptionEndDate: "12 SEP 2025",
  },
  {
    id: 4,
    name: "Alice Smith",
    phone: "+255 754 805 256",
    email: "alice@example.com",
    dateJoined: "23-12-2024 4:35 PM",
    vehicleType: "Toyota Yaris",
    vehicleColor: "Blue",
    AccountType: 'Company Account',
    CompanyName: 'General trucks Ltd',
    licensePlateNumber: "1234AB",
    subscriptionStatus: "ACTIVE",
    subscriptionStartDate: "12 AUG 2024",
    subscriptionEndDate: "12 SEP 2025",
  },
  {
    id: 5,
    name: "Bob Johnson",
    phone: "+255 754 805 256",
    email: "bob@example.com",
    dateJoined: "23-12-2024 4:35 PM",
    vehicleType: "Toyota Yaris",
    vehicleColor: "Blue",
    AccountType: 'Company Account',
    CompanyName: 'General trucks Ltd',
    licensePlateNumber: "1234AB",
    subscriptionStatus: "ACTIVE",
    subscriptionStartDate: "12 AUG 2024",
    subscriptionEndDate: "12 SEP 2025",
  },
  {
    id: 6,
    name: "Charlie Brown",
    phone: "+255 754 805 256",
    email: "charlie@example.com",
    dateJoined: "23-12-2024 4:35 PM",
    vehicleType: "Toyota Yaris",
    vehicleColor: "Blue",
    AccountType: 'Company Account',
    CompanyName: 'General trucks Ltd',
    licensePlateNumber: "1234AB",
    subscriptionStatus: "ACTIVE",
    subscriptionStartDate: "12 AUG 2024",
    subscriptionEndDate: "12 SEP 2025",
  },
  {
    id: 7,
    name: "David Wilson",
    phone: "+255 754 805 256",
    email: "david@example.com",
    dateJoined: "23-12-2024 4:35 PM",
    vehicleType: "Toyota Yaris",
    vehicleColor: "Blue",
    AccountType: 'Company Account',
    CompanyName: 'General trucks Ltd',
    licensePlateNumber: "1234AB",
    subscriptionStatus: "ACTIVE",
    subscriptionStartDate: "12 AUG 2024",
    subscriptionEndDate: "12 SEP 2025",
  },
  {
    id: 8,
    name: "Eva Green",
    phone: "+255 754 805 256",
    email: "eva@example.com",
    dateJoined: "23-12-2024 4:35 PM",
    vehicleType: "Toyota Yaris",
    vehicleColor: "Blue",
    AccountType: 'Company Account',
    CompanyName: 'General trucks Ltd',
    licensePlateNumber: "1234AB",
    subscriptionStatus: "ACTIVE",
    subscriptionStartDate: "12 AUG 2024",
    subscriptionEndDate: "12 SEP 2025",
  },
  {
    id: 9,
    name: "Frank Knight",
    phone: "+255 754 805 256",
    email: "frank@example.com",
    dateJoined: "23-12-2024 4:35 PM",
    vehicleType: "Toyota Yaris",
    vehicleColor: "Blue",
    AccountType: 'Company Account',
    CompanyName: 'General trucks Ltd',
    licensePlateNumber: "1234AB",
    subscriptionStatus: "ACTIVE",
    subscriptionStartDate: "12 AUG 2024",
    subscriptionEndDate: "12 SEP 2025",
  },
  {
    id: 10,
    name: "Grace Lee",
    phone: "+255 754 805 256",
    email: "grace@example.com",
    dateJoined: "23-12-2024 4:35 PM",
    vehicleType: "Toyota Yaris",
    vehicleColor: "Blue",
    AccountType: 'Company Account',
    CompanyName: 'General trucks Ltd',
    licensePlateNumber: "1234AB",
    subscriptionStatus: "ACTIVE",
    subscriptionStartDate: "12 AUG 2024",
    subscriptionEndDate: "12 SEP 2025",
  },
];

interface Driver {
  id: number;
  name: string;
  phone: string;
  email: string;
  dateJoined: string;
  vehicleType?: string; // Added optional fields for the modal
  vehicleColor?: string;
  AccountType?:string;
  CompanyName?:string;
  licensePlateNumber?: string;
  subscriptionStatus?: string;
  subscriptionStartDate?: string;
  subscriptionEndDate?: string;
}

const DriverComponent: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  // const [selectedUser, setSelectedUser] = useState<Driver | null>(null);
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [activationStatus, setActivationStatus] = useState("Activated");

  const navigate = useNavigate();
  

  // row click model handel
  const handleRowClick = (driver: Driver) => {
    if (activationStatus === "Pending Review") {
      navigate(`/admin/drivers/pending-driver/${driver.id}`, { state: { driver } });
    } else if (activationStatus === "Activated"){
      navigate(`/admin/drivers/driver-details/${driver.id}`, { state: { driver } });
    }
  };


  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  // Filter drivers based on search query
  const filteredDrivers = drivers.filter(
    (driver) =>
      driver.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      driver.phone.toLowerCase().includes(searchQuery.toLowerCase()) ||
      driver.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div
      className="p-6 bg-white min-h-screen"
      style={{ maxWidth: "90%", marginLeft: "240px", marginTop: "100px" }}
    >
      <Box sx={{ p: 3 }}>
      <div className="flex justify-between items-center mb-4">
          <Typography variant="h4" gutterBottom color="#2C3E50">
            Drivers
          </Typography>
          <div className="flex items-center gap-4">
            <Select
              value={activationStatus}
              onChange={(e) => setActivationStatus(e.target.value as string)}
              sx={{
                width: 170,
                height: "3rem",
                borderRadius: "10px",
                "& fieldset": {
                  borderRadius: "10px",
                },
              }}
            >
              <MenuItem value="Activated">Activated</MenuItem>
              <MenuItem value="Pending Review">Pending Review</MenuItem>
            </Select>
          
          <div className="relative ">
            <Search
              className="absolute left-4 top-1/2 transform -translate-y-1/2"
              size={20}
              style={{ color: "#FDA214" }} // Change icon color
            />
            <Form.Control
              type="text"
              placeholder="Search Drivers by Name / Department / Status"
              className="pl-10 pr-6 py-2 w-112 rounded-full border border-gray-300"
              value={searchQuery}
              onChange={handleSearch}
              style={{
                width: "30rem",
                height: "3.5rem",
                lineHeight: "3rem",
                borderRadius: "20px",
                textAlign: "center",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }} // Custom width and height
            />
          </div>
        </div>
        </div>

        <TableContainer component={Paper} elevation={0}>
          <Table>
            <TableHead sx={{ backgroundColor: "#F0F3FA" }}>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>User Name</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Phone no.</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell>
                <TableCell sx={{ padding: "30px" , fontWeight: 'bold'}}>
                  Date Joined <KeyboardArrowDown />
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} >Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {filteredDrivers.map((driver) => (
                <TableRow
                  key={driver.id}
                  onClick={() => handleRowClick(driver)}
                  style={{
                    cursor: "pointer",
                    backgroundColor: hoveredRow === driver.id ? "#f0f0f0" : "transparent",
                  }}
                  onMouseEnter={() => setHoveredRow(driver.id)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  <TableCell>{driver.name}</TableCell>
                  <TableCell>{driver.phone}</TableCell>
                  <TableCell>{driver.email}</TableCell>
                  <TableCell>{driver.dateJoined}</TableCell>

                    <TableCell>
                    
                    <Button
                      variant="link"
                      className="text-gray-500 hover:text-gray-700 p-1"
                      onClick={(e) => {
                        e.stopPropagation()
                        
                      }}
                    >
                      <img
                        src={activationStatus === "Activated" ? PersonDash : GreenIcon}
                        alt="Person Icon"
                        width="30"
                        height="30"
                        className="ml-2"
                      />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          className="d-flex justify-content-center  align-items-center mt-4 flex-wrap p-3 rounded-lg"
          style={{ height: "100px" }}
        >
          <div className="d-flex justify-content-center mt-auto flex-wrap">
            {/* Previous Button with Custom Left Arrow */}
            <Button
              variant={currentPage > 1 ? "outline-secondary" : "disabled"}
              size="sm"
              className="mx-1 d-flex align-items-center justify-content-center"
              onClick={() =>
                currentPage > 1 && handlePageChange(currentPage - 1)
              }
              style={{
                backgroundColor: currentPage > 1 ? "#FDA214" : "transparent",
                color: currentPage > 1 ? "#fff" : "#6c757d",
                borderColor: currentPage > 1 ? "#FDA214" : "#6c757d",
              }}
            >
              <img src={LeftArrow} alt="Previous" className="mr-2" width="16" />{" "}
              Previous
            </Button>

            {/* Page Numbers */}
            {[1, 2, 3, "...", 8, 9, 10].map((page, index) => (
              <Button
                key={index}
                variant={currentPage === page ? "primary" : "outline-secondary"}
                size="sm"
                onClick={() =>
                  typeof page === "number" && handlePageChange(page)
                }
                className={`mx-1 ${page === "..." ? "cursor-default" : ""}`}
                style={{
                  backgroundColor:
                    currentPage === page ? "#FDA214" : "transparent",
                  color: currentPage === page ? "#fff" : "#FDA214",
                  borderColor: currentPage === page ? "#FDA214" : "#FDA214",
                }}
                disabled={page === "..."}
              >
                {page}
              </Button>
            ))}

            {/* Next Button with Custom Right Arrow */}
            <Button
              variant={currentPage < 10 ? "outline-secondary" : "disabled"}
              size="sm"
              className="mx-1 d-flex align-items-center justify-content-center"
              onClick={() =>
                currentPage < 10 && handlePageChange(currentPage + 1)
              }
              style={{
                backgroundColor: currentPage < 10 ? "#FDA214" : "transparent",
                color: currentPage < 10 ? "#fff" : "#6c757d",
                borderColor: currentPage < 10 ? "#FDA214" : "#6c757d",
              }}
            >
              Next{" "}
              <img src={RightArrow} alt="Next" className="ml-2" width="16" />
            </Button>
          </div>
        </div>
       
      </Box>
    </div>
  );
};

export default DriverComponent;
