import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LeftArrow from "../../../../assets/icons/arrow-left.svg";
import RightArrow from "../../../../assets/icons/arrow-right.svg";
import { Form, Button } from "react-bootstrap";
import { Search, Gear, X } from "react-bootstrap-icons";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  TextField,
  InputAdornment,
  Grid,
  Button as MuiButton,
} from "@mui/material";

const tickets = [
  {
    id: "1",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-12-2024",
  },
  {
    id: "2",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-12-2024",
  },
  {
    id: "3",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
  },
  {
    id: "4",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
  },
  {
    id: "5",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
  },
  {
    id: "6",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
  },
  {
    id: "7",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
  },
  {
    id: "8",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
  },
  {
    id: "9",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
  },
  {
    id: "10",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
  },
  {
    id: "11",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
  },
  {
    id: "12",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
  },
  {
    id: "13",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-02-2024",
  },
  {
    id: "14",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-08-2024",
  },
  {
    id: "15",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-10-2024",
  },
  {
    id: "16",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-11-2024",
  },
  {
    id: "17",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-01-2024",
  },
  {
    id: "18",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-04-2024",
  },
  {
    id: "19",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-05-2024",
  },
  {
    id: "20",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-07-2024",
  },
  {
    id: "21",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-09-2024",
  },
  {
    id: "22",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-10-2024",
  },
  {
    id: "23",
    name: "Baraka M.Jaa",
    completedTrips: 15,
    totalDistance: "180 km",
    accidentRecovery: 0,
    ravineDitchPrecipice: 3,
    pendingAmount: "$6000",
    amountPaid: "$8000",
    dateOfPayment: "23-11-2024",
  },
];

interface tickest {
  ratePerKM: string;
  rateHillyKm: string;
  accidentRate: string;
  miscellaneousRate: string;
}

// number tickest to be per page
const TICKETS_PER_PAGE = 10;

const SupportTicket: React.FC = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [activationStatus, setActivationStatus] = useState("Paid");
  const [openModal, setOpenModal] = useState(false);
  const [planData, setPlanData] = useState<tickest>({
    ratePerKM: "",
    rateHillyKm: "",
    accidentRate: "",
    miscellaneousRate: "",
  });
  const [selectedDriver, setSelectedDriver] = useState<{
    name: string;
    pendingAmount: string;
  } | null>(null);

  // row click model handel
  const handleRowClick = (driver: {
    id: string;
    name: string;
    pendingAmount: string;
    amountPaid: string;
    dateOfPayment: string;
  }) => {
    if (activationStatus === "Pending") {
      setSelectedDriver(driver);
    } else if (activationStatus === "Paid") {
      navigate(`/admin/driver-payouts/${driver.id}`, {
        state: {
          name: driver.name,
          amountPaid: driver.amountPaid,
          dateOfPayment: driver.dateOfPayment,
        },
      });
    }
  };
  const handleCloseDriverModal = () => {
    setSelectedDriver(null);
  };

  const handleMarkAsPaid = () => {
    console.log(`Marked as paid for ${selectedDriver?.name}`);
    handleCloseDriverModal();
  };
  //

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setPlanData({
      ratePerKM: "",
      rateHillyKm: "",
      accidentRate: "",
      miscellaneousRate: "",
    });
  };

  // model inbox value handel
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPlanData((prevData) => ({ ...prevData, [name]: value }));
  };

  //handel submit
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Payout Rate:", planData);
    handleCloseModal();
  };

  // Calculate the total number of pages
  const totalpages = Math.ceil(tickets.length / TICKETS_PER_PAGE);

  // Slice tickets array based on the current page
  const paginatedTickets = tickets.slice(
    (currentPage - 1) * TICKETS_PER_PAGE,
    currentPage * TICKETS_PER_PAGE
  );

  return (
    <div
      className="p-6 bg-white min-h-screen"
      style={{ maxWidth: "100%", marginLeft: "250px", marginTop: "100px" }}
    >
      <Box sx={{ p: 3.5, bgcolor: "background.paper" }} color="#2C3E50">
        <div className="flex justify-between items-center mb-4">
          <Typography variant="h4" gutterBottom color="#2C3E50">
            Driver Payout
          </Typography>
          <div className="flex items-center gap-4">
            <Select
              value={activationStatus}
              onChange={(e) => setActivationStatus(e.target.value as string)}
              sx={{
                width: 170,
                height: "3rem",
                borderRadius: "10px",
                "& fieldset": {
                  borderRadius: "10px",
                },
              }}
            >
              <MenuItem value="Paid">Paid</MenuItem>
              <MenuItem value="Pending">Pending</MenuItem>
            </Select>

            <div className="relative flex items-center">
              <Search
                className="absolute left-4 top-1/2 transform -translate-y-1/2"
                size={20}
                style={{ color: "#FDA214" }}
              />
              <Form.Control
                type="text"
                placeholder="Search Drivers by Name"
                className="pl-10 pr-6 py-2 rounded-full border border-gray-300"
                value={searchQuery}
                onChange={handleSearch}
                style={{
                  width: "20rem",
                  height: "3rem",
                  lineHeight: "3rem",
                  borderRadius: "20px",
                  textAlign: "left",
                  paddingLeft: "40px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              />
              <Button
                variant="warning"
                className="ml-2 flex items-center justify-center"
                style={{
                  backgroundColor: "#FDA214",
                  color: "#fff",
                  borderRadius: "10px",
                  height: "3rem",
                  width: "10rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "0.5rem",
                }}
                onClick={handleOpenModal}
              >
                <Gear size={18} />
                <span>Payout Rates</span>
              </Button>
            </div>
          </div>
        </div>

        <TableContainer component={Paper} elevation={0}>
          <Table>
            <TableHead sx={{ backgroundColor: "#F0F3FA" }}>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", padding: "16px" }}>
                  Driver Name
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", padding: "16px" }}>
                  Completed Trips
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", padding: "16px" }}>
                  Total Distance (km)
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", padding: "16px" }}>
                  Accident Recovery
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", padding: "16px" }}>
                  Ravine / Ditch / Precipice
                </TableCell>
                {activationStatus === "Paid" ? (
                  <TableCell sx={{ fontWeight: "bold", padding: "16px" }}>
                    Amount Paid
                  </TableCell>
                ) : (
                  <TableCell sx={{ fontWeight: "bold", padding: "16px" }}>
                    Pending Amount
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedTickets.map((ticket) => (
                <TableRow
                  key={ticket.id}
                  onClick={() =>
                    handleRowClick({
                      id: ticket.id,
                      name: ticket.name,
                      pendingAmount: ticket.pendingAmount,
                      amountPaid: ticket.amountPaid,
                      dateOfPayment: ticket.dateOfPayment,
                    })
                  }
                  sx={{
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "#f5f5f5" },
                  }}
                >
                  <TableCell>{ticket.name}</TableCell>
                  <TableCell>{ticket.completedTrips}</TableCell>
                  <TableCell>{ticket.totalDistance}</TableCell>
                  <TableCell>{ticket.accidentRecovery}</TableCell>
                  <TableCell>{ticket.ravineDitchPrecipice}</TableCell>
                  {activationStatus === "Paid" ? (
                    <TableCell>{ticket.amountPaid}</TableCell>
                  ) : (
                    <TableCell>{ticket.pendingAmount}</TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          className="d-flex justify-content-center align-items-center mt-4 flex-wrap p-3 rounded-lg"
          style={{ height: "100px" }}
        >
          <div className="d-flex justify-content-center mt-auto flex-wrap">
            <Button
              variant={currentPage > 1 ? "outline-secondary" : "disabled"}
              size="sm"
              className="mx-1 d-flex align-items-center justify-content-center"
              onClick={() =>
                currentPage > 1 && handlePageChange(currentPage - 1)
              }
              style={{
                backgroundColor: currentPage > 1 ? "#FDA214" : "transparent",
                color: currentPage > 1 ? "#fff" : "#6c757d",
                borderColor: currentPage > 1 ? "#FDA214" : "#6c757d",
              }}
              disabled={currentPage === 1}
            >
              <img src={LeftArrow} alt="Previous" className="mr-2" width="16" />{" "}
              Previous
            </Button>

            {[1, 2, 3, "...", 8, 9, 10].map((page, index) => (
              <Button
                key={index}
                variant={
                  currentPage === totalpages ? "primary" : "outline-secondary"
                }
                size="sm"
                onClick={() =>
                  typeof page === "number" && handlePageChange(page)
                }
                disabled={currentPage === totalpages}
                className={`mx-1 ${page === "..." ? "cursor-default" : ""}`}
                style={{
                  backgroundColor:
                    currentPage === page ? "#FDA214" : "transparent",
                  color: currentPage === page ? "#fff" : "#FDA214",
                  borderColor: currentPage === page ? "#FDA214" : "#FDA214",
                }}
              >
                {page}
              </Button>
            ))}

            <Button
              variant={currentPage < 10 ? "outline-secondary" : "disabled"}
              size="sm"
              className="mx-1 d-flex align-items-center justify-content-center"
              onClick={() =>
                currentPage < 10 && handlePageChange(currentPage + 1)
              }
              style={{
                backgroundColor: currentPage < 10 ? "#FDA214" : "transparent",
                color: currentPage < 10 ? "#fff" : "#6c757d",
                borderColor: currentPage < 10 ? "#FDA214" : "#6c757d",
              }}
            >
              Next{" "}
              <img src={RightArrow} alt="Next" className="ml-2" width="16" />
            </Button>
          </div>
        </div>
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600, // Adjust as per requirement
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              top: "70%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ mb: 3, fontWeight: "bold" }}
            >
              Create Plan
            </Typography>

            <MuiButton
              onClick={handleCloseModal}
              sx={{
                minWidth: "auto",
                p: 0,
                color: "#FDA214",
                fontWeight: "bold",
                mt: -3,
                "&:hover": {
                  bgcolor: "#FB923C",
                  color: "white",
                },
              }}
            >
              <X />
            </MuiButton>
          </Box>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Rate per Km"
                  name="ratePerKm"
                  value={planData.ratePerKM}
                  onChange={handleChange}
                  sx={{
                    mb: 3,
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                  placeholder="00"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Rate per Hilly Km"
                  name="duration"
                  value={planData.rateHillyKm}
                  onChange={handleChange}
                  sx={{
                    mb: 3,
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">months</InputAdornment>
                    ),
                  }}
                  placeholder="00"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Accident Recovery Rate"
                  name="accidentRecoveryRate"
                  value={planData.accidentRate}
                  onChange={handleChange}
                  sx={{
                    mb: 3,
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  placeholder="00"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="miscellaneous Rate"
                  name="miscellaneousRate"
                  value={planData.miscellaneousRate}
                  onChange={handleChange}
                  sx={{
                    mb: 3,
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">KM</InputAdornment>
                    ),
                  }}
                  placeholder="00"
                />
              </Grid>
            </Grid>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
              <MuiButton
                type="submit"
                variant="contained"
                sx={{
                  bgcolor: "#FDA214",
                  color: "white",
                  "&:hover": {
                    bgcolor: "#FB923C",
                  },
                  width: "150px",
                }}
              >
                Save Changes
              </MuiButton>
              <Button
                onClick={handleCloseModal}
                variant="outlined"
                style={{
                  background: "white",
                  color: "#F78409",
                  borderColor: "#F78409",

                  width: "120px",
                  height: "48px",
                  borderRadius: "24px",
                  border: "2px solid #F78409",
                  textTransform: "none",
                  fontSize: "16px",
                  fontWeight: "medium",
                  boxShadow: "none",
                }}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
      <Modal
        open={!!selectedDriver && activationStatus === "Pending"}
        onClose={handleCloseDriverModal}
        aria-labelledby="driver-modal-title"
        aria-describedby="driver-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            p: 4,
            borderRadius: 3,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <MuiButton
              onClick={handleCloseDriverModal}
              sx={{
                minWidth: "auto",
                p: 0,
                color: "#FDA214",
                fontWeight: "bold",
                "&:hover": {
                  bgcolor: "#FB923C",
                  color: "white",
                },
              }}
            >
              <X />
            </MuiButton>
          </Box>
          <Typography
            id="driver-modal-title"
            variant="h6"
            component="h2"
            sx={{
              mb: 2,
              textAlign: "center",
              color: "#2C3E50",
              fontWeight: "bold",
            }}
          >
            {selectedDriver?.name}
          </Typography>
          <Typography
            id="driver-modal-description"
            sx={{ mt: 2, mb: 4, textAlign: "center", color: "#2C3E50" }}
          >
            Amount to be paid
          </Typography>
          <Typography
            id="driver-modal-description"
            sx={{
              mt: 2,
              mb: 4,
              textAlign: "center",
              color: "#2C3E50",
              fontWeight: "bold",
            }}
          >
            {selectedDriver?.pendingAmount}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <MuiButton
              onClick={handleMarkAsPaid}
              variant="contained"
              sx={{
                bgcolor: "#FDA214",
                color: "white",
                "&:hover": {
                  bgcolor: "#FB923C",
                },
                width: "200px",
                borderRadius: "20px",
              }}
            >
              Mark as Paid
            </MuiButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default SupportTicket;
