import React from "react";
import CustomLeftArrow from "../../../../assets/icons/arrow-circle-left.svg";
import PhoneIcon from "../../../../assets/icons/solar_phone-rounded-broken.svg";
import VehicleIcon from "../../../../assets/icons/ph_car-profile.svg";
import LicensePlateIcon from "../../../../assets/icons/vaadin_password.svg";
import EmailIcon from "../../../../assets/icons/solar_letter-broken.svg";
import { Form } from "react-bootstrap";
import { useLocation, useNavigate, Link } from "react-router-dom";
import {
  Typography,
  Box,
  Button,
  Grid,
  Paper,
  Card,
  CardContent,
} from "@mui/material";
import { useState } from "react";
import PdfViewer from "./PdfViewer";
import { LocationOn } from "@mui/icons-material";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { CenterFocusStrong } from "@mui/icons-material";

interface Driver {
  id: number;
  name: string;
  phone: string;
  email: string;
  vehicleType?: string;
  AccountType?: string;
  CompanyName?: string;
  licensePlateNumber?: string;
}
const stats = [
  { label: "No. Of Towing Trips", value: 300 },
  { label: "Total Earned", value: "$ 50,000" },
  { label: "Average Rating", value: 3.5 },
  { label: "Reviews Received", value: 40 },
];

const documents = [
  { name: "Driving License", file: "path/to/driving_license.pdf" },
  { name: "Towing License", file: "path/to/towing_license.pdf" },
  { name: "Insurance Document", file: "path/to/insurance_document.pdf" },
  {
    name: "Vehicle Registration 1",
    file: "path/to/vehicle_registration_1.pdf",
  },
  {
    name: "Vehicle Registration 2",
    file: "path/to/vehicle_registration_2.pdf",
  },
  {
    name: "Vehicle Registration 3",
    file: "path/to/vehicle_registration_3.pdf",
  },
];
const data = [
  { month: "Jan", journeys: 5 },
  { month: "Feb", journeys: 10 },
  { month: "Mar", journeys: 8 },
  { month: "Apr", journeys: 15 },
  { month: "May", journeys: 13 },
  { month: "Jun", journeys: 12 },
  { month: "Jul", journeys: 12 },
  { month: "Aug", journeys: 14 },
  { month: "Sep", journeys: 18 },
  { month: "Oct", journeys: 20 },
  { month: "Nov", journeys: 0 },
  { month: "Dec", journeys: 0 },
];

interface Review {
  text: string;
}

const reviews: Review[] = [
  {
    text: "Marcus was a lifesaver! Quick response, friendly service. Highly recommend!",
  },
  { text: "Marcus arrived promptly and handled my car with care. Great job!" },
  {
    text: "I'm so grateful for Marcus's help. He made a stressful situation much easier.",
  },
  {
    text: "Marcus was professional, efficient, and a pleasure to deal with. A+ service.",
  },
  {
    text: "Thanks, Marcus, for your fast and reliable towing service. You're a star!",
  },
];

interface LocationWidgetProps {
  address: string;
  isPickup: boolean;
}
const LocationWidget: React.FC<LocationWidgetProps> = ({
  address,
  isPickup,
}) => (
  <Box
    sx={{
      padding: 2,
      backgroundColor: "#E8ECF4",
      borderRadius: 3,
      display: "flex",
      alignItems: "center",
      position: "relative",
      
    }}
  >
    <Box
      sx={{
        width: 24,
        height: 24,
        borderRadius: "50%",
        backgroundColor: isPickup ? "#FF6B00" : "#8B3DFF",
        border: "6px solid white",
        flexShrink: 0,
        position: "relative",
        zIndex: 2,
      }}
    />
    <Typography
      sx={{
        marginLeft: 2,
        fontSize: 16,
        color: "#0E2E5D",
        lineHeight: 1.5,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
      }}
    >
      {address}
    </Typography>
  </Box>
);

interface ConnectedLocationWidgetsProps {
  pickupAddress: string;
  dropoffAddress: string;
}
const ConnectedLocationWidgets: React.FC<ConnectedLocationWidgetsProps> = ({
  pickupAddress,
  dropoffAddress,
}) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
        <LocationWidget address={pickupAddress} isPickup={true} />
        <LocationWidget address={dropoffAddress} isPickup={false} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          left: 28,
          top: 40,
          bottom: 40,
          width: 5,
          background: "linear-gradient(to bottom, #FF6B00, #FB25FF, #8B3DFF)",
          zIndex: 1,
        }}
      />
    </Box>
  );
};

interface TripInfo {
  name: string;
  timestamp: string;
  vehicle: string;
  distance: string;
  duration: string;
  pickupLocation: string;
  dropoffLocation: string;
  notes: string;
  address: string;
  isPickup: boolean;
}

const TripCard = ({ trip }: { trip: TripInfo }) => (
  <Box
    sx={{
      maxWidth: 345,
      width: "100%",
      height: "100%",
      bgcolor: "background.paper",
      borderRadius: 2,
      boxShadow: 1,
      overflow: "hidden",
    }}
  >
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Typography variant="h6" component="div" color="primary">
          {trip.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {trip.timestamp}
        </Typography>
      </Box>
      <Typography variant="body2" gutterBottom>
        Vehicle: {trip.vehicle}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Typography variant="body2">Distance: {trip.distance}</Typography>
        <Typography variant="body2">Duration: {trip.duration}</Typography>
      </Box>
      <ConnectedLocationWidgets
        pickupAddress={trip.pickupLocation}
        dropoffAddress={trip.dropoffLocation}
      />
      <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
        Notes for driver: {trip.notes}
      </Typography>
      <Box
        component="button"
        sx={{
          mt: 2,
          width: "100%",
          py: 1,
          px: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "transparent",
          border: "1px solid",
          borderColor: "primary.main",
          borderRadius: 1,
          color: "primary.main",
          cursor: "pointer",
          "&:hover": {
            bgcolor: "primary.main",
            color: "white",
          },
        }}
      >
        <Box
          component="span"
          sx={{ mr: 1, display: "flex", alignItems: "center" }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 13.5C13.3807 13.5 14.5 12.3807 14.5 11C14.5 9.61929 13.3807 8.5 12 8.5C10.6193 8.5 9.5 9.61929 9.5 11C9.5 12.3807 10.6193 13.5 12 13.5Z"
              stroke="currentColor"
              strokeWidth="1.5"
            />
            <path
              d="M12 21C16.1421 21 19.5 17.6421 19.5 13.5C19.5 9.35786 12 3 12 3C12 3 4.5 9.35786 4.5 13.5C4.5 17.6421 7.85786 21 12 21Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
        View Trip on Map
      </Box>
    </Box>
  </Box>
);
const trips: TripInfo[] = Array(6).fill({
  name: "Sarah",
  timestamp: "24-09-2024 4:35 PM",
  vehicle: "Toyota Yaris, 5914 AB",
  distance: "108 KM",
  duration: "2h 30m",
  pickupLocation:
    "Pickup Location full address exact address long, picked from google map",
  dropoffLocation:
    "Dropoff Location full address exact address long, picked from google map",
  notes: "Please bring extra long hooks, chains and some water",
});

export default function PendingDriver() {
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const driver = location.state?.driver as Driver;

  if (!driver) {
    return <div>No driver data available</div>;
  }
  const handleApprove = () => {
    // Implement approval logic here
    console.log("Driver approved");
    navigate("/drivers");
  };

  const handleReject = () => {
    // Implement rejection logic here
    console.log("Driver rejected");
    navigate("/drivers");
  };

  return (
    <Box
      sx={{
        overflowX: "auto",
        backgroundColor: "white",
        padding: "60px",
        minHeight: "100vh",
        marginLeft: "230px",
      }}
    >
      <Box
        sx={{
          width: "160%", // Ensures horizontal scroll if content exceeds viewport width
          maxWidth: "1170px",
          marginX: "auto",
          marginTop: "40px",
        }}
      >
        <Link
          to="/admin/drivers"
          style={{
            textDecoration: "none",
            color: "inherit",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={CustomLeftArrow}
            alt="Back"
            style={{ width: 45, height: 55, marginRight: 10, marginBottom: 10 }}
          />
          <Typography variant="h4" gutterBottom color="#2C3E50">
            Driver Details
          </Typography>
          <div className="mt-8 flex justify-end space-x-4"></div>
        </Link>
        <div className="flex items-center mb-6">
          <img
            src={`https://i.pravatar.cc/150?u=${driver.id}`}
            alt={driver.name}
            className="w-20 h-20 rounded-full mr-4"
          />
          <h2 className="text-2xl font-semibold">{driver.name}</h2>

          {/* Wrapper for buttons with ml-auto to push to the right */}
          <div className="ml-auto flex space-x-4">
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#961F16",
                color: "white",
                "&:hover": {
                  backgroundColor: "#7a1612", // Optional: darken the color on hover
                },
              }}
              onClick={handleReject}
            >
              Remove Driver
            </Button>
          </div>
        </div>

        <Box sx={{ px: 2, py: 4 }}>
          <Grid container spacing={4}>
            {stats.map((stat, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Paper
                  sx={{
                    p: 3,
                    textAlign: "left", // Aligns content to the left within the Paper
                    backgroundColor: "#f4f8fb",
                    borderRadius: 2,
                  }}
                >
                  <Typography variant="h6" color="#345C72" fontWeight="medium">
                    {stat.label}
                  </Typography>
                  <Typography
                    variant="h6"
                    color="#345C72"
                    fontWeight="bold"
                    component="div"
                  >
                    {stat.value}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>

        <div className="grid grid-cols-3 gap-6 mb-6">
          {/* First Row */}
          <Form.Group>
            <Form.Label>Phone Number</Form.Label>
            <img
              src={PhoneIcon}
              alt="Phone Icon"
              className="absolute left-2 top-10 w-7 h-5"
            />
            <Form.Control type="text" value={driver.phone} readOnly />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <img
              src={EmailIcon}
              alt="Email Icon"
              className="absolute left-2 top-10 w-7 h-5"
            />
            <Form.Control type="email" value={driver.email} readOnly />
          </Form.Group>
          <Form.Group>
            <Form.Label>Vehicle Type</Form.Label>
            <img
              src={VehicleIcon}
              alt="Vehicle Icon"
              className="absolute left-2 top-10 w-7 h-5"
            />
            <Form.Control
              type="text"
              value={driver.vehicleType || "N/A"}
              readOnly
            />
          </Form.Group>

          {/* Second Row */}
          <Form.Group>
            <Form.Label>License Plate Number</Form.Label>
            <img
              src={LicensePlateIcon}
              alt="License Plate Icon"
              className="absolute left-2 top-10 w-7 h-5"
            />
            <Form.Control
              type="text"
              value={driver.licensePlateNumber || "N/A"}
              readOnly
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Account Type</Form.Label>
            <Form.Control
              type="text"
              value={driver.AccountType || "N/A"}
              readOnly
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              type="text"
              value={driver.CompanyName || "N/A"}
              readOnly
            />
          </Form.Group>
        </div>

        <div className="mt-6 space-y-4">
          <Typography variant="h6" gutterBottom color="#2C3E50">
            Documents
          </Typography>
          {/* Third Row */}
          <div className="grid grid-cols-3 gap-4">
            {documents.slice(0, 3).map((doc, index) => (
              <div
                key={index}
                className="border p-4 rounded cursor-pointer"
                onClick={() => setSelectedFile(doc.file)}
              >
                <p className="font-medium">{doc.name}</p>
                <p className="text-sm text-gray-500">document.pdf</p>
              </div>
            ))}
          </div>
          {/* Fourth Row */}
          <div className="grid grid-cols-3 gap-4 mt-4">
            {documents.slice(3, 6).map((doc, index) => (
              <div
                key={index}
                className="border p-4 rounded cursor-pointer"
                onClick={() => setSelectedFile(doc.file)}
              >
                <p className="font-medium">{doc.name}</p>
                <p className="text-sm text-gray-500">document.pdf</p>
              </div>
            ))}
          </div>
          {/* PDF Viewer */}
          {selectedFile && (
            <div className="mt-6">
              <PdfViewer fileUrl={selectedFile} />
            </div>
          )}
        </div>
        <div className="p-6 mb-6 border rounded-lg mt-10 mb-6 ">
          <Typography
            variant="h5"
            gutterBottom
            color="#E5E5EA"
            textAlign="center"
          >
            Journeys - 2024
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              data={data}
              margin={{ top: 20, right: 30, left: 30, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="journeys"
                stroke="#F39C12"
                strokeWidth={2}
                dot={{ r: 4 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <Box sx={{ maxWidth: "1200px", mx: "auto", p: 4, borderRadius: 10 }}>
          <Box
            sx={{ display: "flex", gap: 12, justifyContent: "space-between" }}
          >
            {/* Left Column */}
            <Box
              sx={{
                flex: 5,
                border: "1px solid #e0e0e0",
                borderRadius: 2,
                padding: 2,
                margin: -4,
              }}
            >
              <Typography
                variant="h5"
                component="h2"
                align="center"
                gutterBottom
              >
                Reviews
              </Typography>
              {reviews.map((review, index) => (
                <Card
                  key={index}
                  sx={{
                    mb: 2,
                    backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#ffffff",
                  }}
                >
                  <CardContent>
                    <Typography variant="body2" color="textSecondary">
                      {review.text}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Box>

            {/* Right Column */}

            <Box
              sx={{
                flex: 5,
                border: "1px solid #e0e0e0",
                borderRadius: 2,
                padding: 2,
                margin: -4,
              }}
            >
              <Typography
                variant="h5"
                component="h2"
                align="center"
                gutterBottom
              >
                Reviews
              </Typography>
              {reviews.map((review, index) => (
                <Card
                  key={index}
                  sx={{
                    mb: 2,
                    backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#ffffff",
                  }}
                >
                  <CardContent>
                    <Typography variant="body2" color="textSecondary">
                      {review.text}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Box>
        </Box>
        <Box sx={{ maxWidth: 1300, margin: "auto", p: -4, marginTop: 5 }}>
          <Grid container spacing={2}>
            {trips.map((trip, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <TripCard trip={trip} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
