// subscriptionSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SubscriptionPlanData {
  id: number;
  planName: string;
  duration: string;
  price: string;
  distanceCovered: string;
  journeysIncluded: string;
  ratePerExtraKM: string;
  accidentRecoveryFee: string;
  precipiceFee: string;
  banner: File | null;
}

const initialState: SubscriptionPlanData[] = [];

const subscriptionSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setPlans: (state, action: PayloadAction<SubscriptionPlanData[]>) => {
      return action.payload;
    },
    addPlan: (state, action: PayloadAction<SubscriptionPlanData>) => {
      state.push(action.payload);
    },
    updatePlan: (state, action: PayloadAction<SubscriptionPlanData>) => {
      const index = state.findIndex(plan => plan.id === action.payload.id);
      if (index !== -1) state[index] = action.payload;
    },
    deletePlan: (state, action: PayloadAction<number>) => {
      return state.filter(plan => plan.id !== action.payload);
    },
  },
});

export const { setPlans, addPlan, updatePlan, deletePlan } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
