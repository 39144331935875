import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import { X } from "react-bootstrap-icons";
import {
  addPlan,
  updatePlan,
  deletePlan,
  setPlans,
} from "../../../redux/slices/subscriptionSlice";
import { Button } from "react-bootstrap";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  TextField,
  InputAdornment,
  Grid,
  Button as MuiButton,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import UploadIcon from "../../../assets/icons/upload.svg";
import LeftArrow from "../../../assets/icons/arrow-left.svg";
import RightArrow from "../../../assets/icons/arrow-right.svg";
import Edit from "../../../assets/icons/edit.svg";
import Delete from "../../../assets/icons/delete.svg";

const subscriptions = [
  {
    name: "Baraka M.Jaa",
    email: "emailaddressfull@gmail.com",
    plan: "Plan 1",
    startDate: "23-12-2020",
    endDate: "23-12-2024",
  },
  {
    name: "John",
    email: "emailaddress@gmail.com",
    plan: "Plan 2",
    startDate: "23-12-2020",
    endDate: "23-12-2024",
  },
  {
    name: "Salma Godwin",
    email: "emailaddress@gmail.com",
    plan: "Plan 2",
    startDate: "23-12-2020",
    endDate: "23-12-2024",
  },
  {
    name: "Abdul",
    email: "emailaddressfull@gmail.com",
    plan: "Plan 2",
    startDate: "23-12-2020",
    endDate: "23-12-2024",
  },
  {
    name: "Joshua Mick",
    email: "emailaddress@gmail.com",
    plan: "Plan 1",
    startDate: "23-12-2020",
    endDate: "23-12-2024",
  },
  {
    name: "Christina",
    email: "emailaddressfull@gmail",
    plan: "Plan 1",
    startDate: "23-12-2020",
    endDate: "23-12-2024",
  },
  {
    name: "Christina",
    email: "emailaddressfull@gmail",
    plan: "Plan 2",
    startDate: "23-12-2020",
    endDate: "23-12-2024",
  },
  {
    name: "Christina",
    email: "emailaddressfull@gmail",
    plan: "Plan 1",
    startDate: "23-12-2020",
    endDate: "23-12-2024",
  },
  {
    name: "Christina",
    email: "emailaddressfull@gmail",
    plan: "Plan 1",
    startDate: "23-12-2020",
    endDate: "23-12-2024",
  },
  {
    name: "Christina",
    email: "emailaddressfull@gmail",
    plan: "Plan 2",
    startDate: "23-12-2020",
    endDate: "23-12-2024",
  },
];

interface SubscriptionPlanData {
  id: number;
  planName: string;
  duration: string;
  price: string;
  distanceCovered: string;
  journeysIncluded: string;
  ratePerExtraKM: string;
  accidentRecoveryFee: string;
  precipiceFee: string;
  banner: File | null;
}

// interface CreatePlanModalProps {
//   open: boolean;
//   onClose: () => void;
//   onSubmit: (planData: SubscriptionPlanData, banners: File[]) => void;
// }

const Subscriptions: React.FC = () => {
  // redux
  const dispatch = useDispatch<AppDispatch>();
  const plans = useSelector((state: RootState) => state.subscriptions);
  const [currentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [editingPlan, setEditingPlan] = useState<SubscriptionPlanData | null>(
    null
  );
  const [planData, setPlanData] = useState<SubscriptionPlanData>({
    id: 0,
    planName: "",
    duration: "",
    price: "",
    distanceCovered: "",
    journeysIncluded: "",
    ratePerExtraKM: "",
    accidentRecoveryFee: "",
    precipiceFee: "",
    banner: null,
  });
  const [banners, setBanners] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  // New state for delete confirmation modal //
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [planToDelete, setPlanToDelete] = useState<SubscriptionPlanData | null>(
    null
  );

  useEffect(() => {
    const savedPlans = localStorage.getItem("plans");
    if (savedPlans) {
      dispatch(setPlans(JSON.parse(savedPlans)));
    }
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("plans", JSON.stringify(plans));
  }, [plans]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setPlanData({
      id: 0,
      planName: "",
      duration: "",
      price: "",
      distanceCovered: "",
      journeysIncluded: "",
      ratePerExtraKM: "",
      accidentRecoveryFee: "",
      precipiceFee: "",
      banner: null,
    });
    setEditingPlan(null);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (editingPlan) {
      dispatch(updatePlan({ ...planData, id: editingPlan.id }));
    } else {
      dispatch(addPlan({ ...planData, id: Date.now() }));
    }
    handleCloseModal();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPlanData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleBannerUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const newBanners = Array.from(e.target.files);

      setBanners(() => {
        // Only keep the latest upload, replacing any existing one
        return [newBanners[0]];
      });
    }

    // for two render (bannar)

    // if (e.target.files) {
    //   const newBanners = Array.from(e.target.files);
    //   setBanners((prevBanners) =>{
    //     if(prevBanners.length >= 2){
    //       return [prevBanners[0],newBanners[0]]; // Keep first, replace second
    //     }
    //     return [...prevBanners, ...newBanners].slice(0,2);
    //   })
    // }
  };

  const handleRemoveBanner = (index: number) => {
    setBanners((prevBanners) => prevBanners.filter((_, i) => i !== index));
  };

  // const handleSubmit = (e: React.FormEvent) => {
  //   e.preventDefault();
  //   if (plans.length < 4) {
  //     if (editingPlan) {
  //       setPlans(
  //         plans.map((plan) =>
  //           plan.id === editingPlan.id
  //             ? { ...planData, id: editingPlan.id }
  //             : plan
  //         )
  //       );
  //     } else {
  //       setPlans([...plans, { ...planData, id: Date.now() }]);
  //     }
  //   }
  //   // console.log("New Subscription Plan:", planData);
  //   // Here you would typically send this data to your backend
  //   handleCloseModal();
  // };

  const handleEdit = (plan: SubscriptionPlanData) => {
    setEditingPlan(plan);
    setPlanData(plan);
    setOpenModal(true);
  };

  // Modified delete handling ?? //

  // const handleDelete = (id: number) => {
  //   dispatch(deletePlan(id));
  // };

  const handleDeleteClick = (plan: SubscriptionPlanData) => {
    setPlanToDelete(plan);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (planToDelete) {
      dispatch(deletePlan(planToDelete.id));
      setDeleteModalOpen(false);
      setPlanToDelete(null);
    }
  };
  const handleDeleteCancel = () => {
    setDeleteModalOpen(false);
    setPlanToDelete(null);
  };

  return (
    <div
      className="p-6 bg-white min-h-screen"
      style={{ maxWidth: "100%", marginLeft: "250px", marginTop: "100px" }}
    >
      <Box sx={{ p: 3.5, bgcolor: "background.default" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 5,
          }}
        >
          <Typography variant="h4" color="#2C3E50">
            Subscriptions
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="contained"
              style={{
                width: "18rem",
                height: "3rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#FDA214",
                color: "white",
                fontWeight: 300,
                fontSize: "1.1rem",
              }}
              onClick={handleOpenModal}
            >
              <AddIcon style={{ marginRight: "10px" }} />
              Subscription Plan
            </Button>
            {/* <Button
              variant="outlined"
              style={{
                width: "17.5rem",
                height: "3rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "orange",
                borderColor: "#FDA214",
                fontWeight: 300,
                fontSize: "1.1rem",
              }}
            >
              <img
                src={UploadIcon}
                alt="Upload Icon"
                width={30}
                height={30}
                style={{ marginRight: "10px" }}
              />
              Promotional Banner
            </Button> */}
          </Box>
        </Box>

        <Box sx={{ display: "flex", mb: 3 }}>
          <Paper
            sx={{
              flex: 1,
              mr: 2,
              p: 2,
              background: "#F0F3FA",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold" }}
              color="#345C72"
            >
              Total Revenue
            </Typography>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", textAlign: "right", color: "#345C72" }}
            >
              $ 50,000
            </Typography>
          </Paper>
          <Paper
            sx={{
              flex: 1,
              p: 2,
              background: "#F0F3FA",
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold" }}
              color="#345C72"
            >
              Total Subscribers
            </Typography>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", textAlign: "right", color: "#345C72" }}
            >
              5000
            </Typography>
          </Paper>
        </Box>

        {/* Subscription Plan Cards */}

        <Box sx={{ display: "flex", gap: 2, mb: 3, flexWrap: "wrap" }}>
          {plans.map((plan: SubscriptionPlanData) => (
            <Card
              key={plan.id}
              sx={{
                width: "calc(25% - 16px)",
                minWidth: 200,
                backgroundColor: "#F0F3FA",
              }}
            >
              <CardContent color="#345C72">
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "left",
                    color: "#345C72",
                  }}
                >
                  {`Plan Name ${plan.planName}`}
                </Typography>
              </CardContent>
              <CardActions>
                <MuiButton
                  size="small"
                  onClick={() => handleEdit(plan)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#FDA214",
                      color: "white",
                      "& img": {
                        filter: "brightness(0) invert(1)", // This makes the image appear white
                      },
                    },
                  }}
                >
                  <img src={Edit} alt="Edit" className="mr-2" width="16" />
                </MuiButton>
                <MuiButton
                  size="small"
                  onClick={() => handleDeleteClick(plan)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#FDA214",
                      color: "white",
                      "& img": {
                        filter: "brightness(0) invert(1)", // This makes the image appear white
                      },
                    },
                  }}
                >
                  <img src={Delete} alt="Delete" className="mr-2" width="16" />
                </MuiButton>
              </CardActions>
            </Card>
          ))}
        </Box>

        <TableContainer component={Paper} elevation={0}>
          <Table>
            <TableHead sx={{ backgroundColor: "#F0F3FA" }}>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Email</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Plan</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Start Date</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>End Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptions.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.plan}</TableCell>
                  <TableCell>{row.startDate}</TableCell>
                  <TableCell>{row.endDate}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div
          className="d-flex justify-content-center align-items-center mt-4 flex-wrap p-3 rounded-lg"
          style={{ height: "100px" }}
        >
          <div className="d-flex justify-content-center mt-auto flex-wrap">
            <Button
              variant={currentPage > 1 ? "outline-secondary" : "disabled"}
              size="sm"
              className="mx-1 d-flex align-items-center justify-content-center"
              onClick={() =>
                currentPage > 1 && handlePageChange(currentPage - 1)
              }
              style={{
                backgroundColor: currentPage > 1 ? "#FDA214" : "transparent",
                color: currentPage > 1 ? "#fff" : "#6c757d",
                borderColor: currentPage > 1 ? "#FDA214" : "#6c757d",
              }}
            >
              <img src={LeftArrow} alt="Previous" className="mr-2" width="16" />{" "}
              Previous
            </Button>

            {[1, 2, 3, "...", 8, 9, 10].map((page, index) => (
              <Button
                key={index}
                variant={currentPage === page ? "primary" : "outline-secondary"}
                size="sm"
                onClick={() =>
                  typeof page === "number" && handlePageChange(page)
                }
                className={`mx-1 ${page === "..." ? "cursor-default" : ""}`}
                style={{
                  backgroundColor:
                    currentPage === page ? "#FDA214" : "transparent",
                  color: currentPage === page ? "#fff" : "#FDA214",
                  borderColor: currentPage === page ? "#FDA214" : "#FDA214",
                }}
                disabled={page === "..."}
              >
                {page}
              </Button>
            ))}

            <Button
              variant={currentPage < 10 ? "outline-secondary" : "disabled"}
              size="sm"
              className="mx-1 d-flex align-items-center justify-content-center"
              onClick={() =>
                currentPage < 10 && handlePageChange(currentPage + 1)
              }
              style={{
                backgroundColor: currentPage < 10 ? "#FDA214" : "transparent",
                color: currentPage < 10 ? "#fff" : "#6c757d",
                borderColor: currentPage < 10 ? "#FDA214" : "#6c757d",
              }}
            >
              Next{" "}
              <img src={RightArrow} alt="Next" className="ml-2" width="16" />
            </Button>
          </div>
        </div>
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 730, // Adjust as per requirement
            maxHeight: "90vh",
            overflowY: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            "&::-webkit-scrollbar": {
              width: "8px", // Adjust the scrollbar width
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "white", // Scrollbar track color
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "white", // Scrollbar thumb color
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "white", // Hover color for scrollbar thumb
              },
            },
          }}
        >
           <Box
            sx={{
              display: "flex",
              top: "70%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ mb: 3, fontWeight: "bold" }}
            >
              Create Plan
            </Typography>

            <MuiButton
              onClick={handleCloseModal}
              sx={{
                minWidth: "auto",
                p: 0,
                color: "#FDA214",
                fontWeight: "bold",
                mt: -3,
                "&:hover": {
                  bgcolor: "#FB923C",
                  color: "white",
                },
              }}
            >
              <X />
            </MuiButton>
          </Box>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Plan Name"
                  name="planName"
                  value={planData.planName}
                  onChange={handleChange}
                  sx={{
                    mb: 3,
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                  placeholder="xyz"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Duration"
                  name="duration"
                  value={planData.duration}
                  onChange={handleChange}
                  sx={{
                    mb: 3,
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">months</InputAdornment>
                    ),
                  }}
                  placeholder="00"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Price"
                  name="price"
                  value={planData.price}
                  onChange={handleChange}
                  sx={{
                    mb: 3,
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  placeholder="300"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Distance Cover per Jour"
                  name="distanceCovered"
                  value={planData.distanceCovered}
                  onChange={handleChange}
                  sx={{
                    mb: 3,
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">KM</InputAdornment>
                    ),
                  }}
                  placeholder="00"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="No. of Journeys Included"
                  name="journeysIncluded"
                  value={planData.journeysIncluded}
                  onChange={handleChange}
                  sx={{
                    mb: 3,
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                  placeholder="6"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Rate per extra KM"
                  name="ratePerExtraKM"
                  value={planData.ratePerExtraKM}
                  onChange={handleChange}
                  sx={{
                    mb: 3,
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  placeholder="3000"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Accident Recovery fee"
                  name="accidentRecoveryFee"
                  value={planData.accidentRecoveryFee}
                  onChange={handleChange}
                  sx={{
                    mb: 3,
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  placeholder="00"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Ravine/Ditch/Precipice Fee"
                  name="precipiceFee"
                  value={planData.precipiceFee}
                  onChange={handleChange}
                  sx={{
                    mb: 3,
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  placeholder="00"
                />
              </Grid>
            </Grid>

            {/* Banner upload section */}
            <Box sx={{ mt: 3, mb: 3 }}>
              <Typography variant="subtitle1" sx={{ mb: 2 }}>
                Uploaded Banners
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                {banners.map((banner, index) => (
                  <Box
                    key={index}
                    sx={{ position: "relative", width: "100%", height: "auto" }}
                  >
                    <img
                      src={URL.createObjectURL(banner)}
                      alt={`Banner ${index + 1}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                    <MuiButton
                      onClick={() => handleRemoveBanner(index)}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        minWidth: "auto",
                        p: 0.5,
                        bgcolor: "rgba(0,0,0,0.5)",
                        color: "white",
                        "&:hover": { bgcolor: "rgba(0,0,0,0.7)" },
                      }}
                    >
                      X
                    </MuiButton>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                border: "2px dashed #FDA214",
                borderRadius: 2,
                p: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                cursor: "pointer",
              }}
              onClick={() => fileInputRef.current?.click()}
            >
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleBannerUpload}
                accept="image/jpeg,image/png,image/jpg"
                multiple
              />
              <img
                src={UploadIcon}
                alt="Upload Icon"
                width={30}
                height={30}
                style={{ marginRight: "10px", justifyContent: "left" }}
              />
              <Typography sx={{ mt: 1, color: "#FDA214" }}>
                Upload Banner (JPG, PNG or JPEG)
              </Typography>
            </Box>

            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
              <MuiButton
                type="submit"
                variant="contained"
                sx={{
                  bgcolor: "#FDA214",
                  color: "white",
                  "&:hover": {
                    bgcolor: "#FB923C",
                  },
                  width: "100px",
                }}
              >
                {editingPlan ? "Update" : "Create"}
              </MuiButton>
              <Button
                onClick={handleCloseModal}
                variant="outlined"
                style={{
                  background: "white",
                  color: "#F78409",
                  borderColor: "#F78409",

                  width: "120px",
                  height: "48px",
                  borderRadius: "24px",
                  border: "2px solid #F78409",
                  textTransform: "none",
                  fontSize: "16px",
                  fontWeight: "medium",
                  boxShadow: "none",
                }}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
      {/* Delete Confirmation Modal */}
      <Dialog
        open={deleteModalOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-confirmation-dialog-title"
        PaperProps={{
          style: {
            borderRadius: "16px",
            padding: "24px",
            maxWidth: "400px",
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <MuiButton
              onClick={handleDeleteCancel}
              sx={{
                minWidth: "auto",
                p: 0,
                color: "#FDA214",
                fontWeight: "bold",
                "&:hover": {
                  bgcolor: "#FB923C",
                  color: "white",
                },
              }}
            >
              <X />
            </MuiButton>
          </Box>
        <DialogTitle
          id="delete-confirmation-dialog-title"
          style={{
            fontWeight: "bold",
            color: "#2C3E50",
            textAlign: "center",
            paddingBottom: "8px",
          }}
        >
          {planToDelete ? `Plan Name ${planToDelete.planName}` : ""}
        </DialogTitle>
        <DialogContent>
          <p
            style={{
              textAlign: "center",
              color: "#345C72",
              marginBottom: "24px",
            }}
          >
            Are you sure you want to delete this plan?
          </p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <MuiButton
              onClick={handleDeleteConfirm}
              variant="contained"
              style={{
                backgroundColor: "#FDA214",
                color: "white",
                borderRadius: "20px",
                padding: "10px 24px",
                textTransform: "none",
                fontWeight: "bold",
              }}
            >
              Delete
            </MuiButton>
            <MuiButton
              onClick={handleDeleteCancel}
              variant="outlined"
              sx={{
                color: "#FDA214",
                borderColor: "#FDA214",
                borderRadius: "20px",
                padding: "10px 24px",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "transparent", // Removes background color on hover
                  borderColor: "#FDA214", // Keeps border color the same
                },
              }}
            >
              Cancel
            </MuiButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Subscriptions;
