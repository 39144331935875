import React from 'react';

const ContactDetail: React.FC = () => {
  return (
    <div className="p-4">
      <h2 className="text-center font-bold">User Management</h2>
      <p>This is the User Management section.</p>
      {/* Add more content as needed */}
    </div>
  );
};

export default ContactDetail;
