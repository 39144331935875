import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import LeftArrow from "../../../../assets/icons/arrow-left.svg";
import RightArrow from "../../../../assets/icons/arrow-right.svg";
import CustomLeftArrow from "../../../../assets/icons/arrow-circle-left.svg";
import { useParams, Link, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  Avatar,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ArrowLeft } from "lucide-react";

interface  PaymentHistory {
  date: string;
  amount: string;
}
const TICKETS_PER_PAGE = 10;

export default function DriverPayoutDetails() {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const { name, amountPaid, dateOfPayment } = location.state || {};
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  // Calculate the total number of pages
  const totalpages = Math.ceil(dateOfPayment.length / TICKETS_PER_PAGE);

  const dateOfPaymentArray = Array.isArray(dateOfPayment) 
  ? dateOfPayment 
  : [{ date: dateOfPayment, amount: amountPaid }]; // Wrap single record in array

  const paginatedTickets: PaymentHistory[] = dateOfPaymentArray.slice(
    (currentPage - 1) * TICKETS_PER_PAGE,
    currentPage * TICKETS_PER_PAGE
  );

  return (
    <div
      className="p-6 bg-white min-h-screen"
      style={{ maxWidth: "100%", marginLeft: "250px", marginTop: "100px" }}
    >
      <Box sx={{ p: 3, bgcolor: "background.default", minHeight: "100vh" }}>
        <Link
          to="/admin/driver-payouts"
          style={{
            textDecoration: "none",
            color: "inherit",
            display: "flex",
            alignItems: "center",
          }}
        >
        <img src={CustomLeftArrow} alt="Back" style={{ width: 45, height: 55, marginRight: 10, marginBottom: 10 }}/>
          <Typography variant="h4" gutterBottom color="#2C3E50">
            Driver Payout Details
          </Typography>
        </Link>

        <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
          <Avatar
            src="/placeholder.svg?height=50&width=50"
            alt={name}
            sx={{ width: 50, height: 50, mr: 2 }}
          />
          <Typography variant="h5">{name}</Typography>
        </Box>

        <Grid container spacing={2} sx={{ mb: 4 }}>
          {[
            { label: "No. Of Towing Trips", value: "300" },
            { label: "Total Earned", value: "$ 50,000" },
            { label: "Average Rating", value: "3.5" },
            { label: "Reviews Received", value: "40" },
          ].map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Paper sx={{ p: 2, textAlign: "left", bgcolor: "#F0F3FA" }}>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  color="#345C72"
                  gutterBottom
                >
                  {item.label}
                </Typography>
                <Typography variant="h4" sx={{ fontWeight: 'bold' }} color="#345C72" gutterBottom>
                  {item.value}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <TableContainer component={Paper} elevation={0} color="#2C3E50">
          <Table>
            <TableHead sx={{ backgroundColor: "#F0F3FA" }}>
              <TableRow color="#2C3E50">
                <TableCell sx={{ fontWeight: "bold" }}>
                  Date of payment
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Amount Paid</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedTickets.map((ticket: PaymentHistory, index: number) => (
                <TableRow key={index}>
                  <TableCell>{ticket.date}</TableCell>
                  <TableCell>{ticket.amount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <div
            className="d-flex justify-content-center align-items-center mt-4 flex-wrap p-3 rounded-lg"
            style={{ height: "100px" }}
          >
            <div className="d-flex justify-content-center mt-auto flex-wrap">
              <Button
                variant={currentPage > 1 ? "outline-secondary" : "disabled"}
                size="sm"
                className="mx-1 d-flex align-items-center justify-content-center"
                onClick={() =>
                  currentPage > 1 && handlePageChange(currentPage - 1)
                }
                style={{
                  backgroundColor: currentPage > 1 ? "#FDA214" : "transparent",
                  color: currentPage > 1 ? "#fff" : "#6c757d",
                  borderColor: currentPage > 1 ? "#FDA214" : "#6c757d",
                }}
                disabled={currentPage === 1}
              >
                <img
                  src={LeftArrow}
                  alt="Previous"
                  className="mr-2"
                  width="16"
                />{" "}
                Previous
              </Button>

              {[1, 2, 3, "...", 8, 9, 10].map((page, index) => (
                <Button
                  key={index}
                  variant={
                    currentPage === totalpages ? "primary" : "outline-secondary"
                  }
                  size="sm"
                  onClick={() =>
                    typeof page === "number" && handlePageChange(page)
                  }
                  disabled={currentPage === totalpages}
                  className={`mx-1 ${page === "..." ? "cursor-default" : ""}`}
                  style={{
                    backgroundColor:
                      currentPage === page ? "#FDA214" : "transparent",
                    color: currentPage === page ? "#fff" : "#FDA214",
                    borderColor: currentPage === page ? "#FDA214" : "#FDA214",
                  }}
                >
                  {page}
                </Button>
              ))}

              <Button
                variant={currentPage < 10 ? "outline-secondary" : "disabled"}
                size="sm"
                className="mx-1 d-flex align-items-center justify-content-center"
                onClick={() =>
                  currentPage < 10 && handlePageChange(currentPage + 1)
                }
                style={{
                  backgroundColor: currentPage < 10 ? "#FDA214" : "transparent",
                  color: currentPage < 10 ? "#fff" : "#6c757d",
                  borderColor: currentPage < 10 ? "#FDA214" : "#6c757d",
                }}
              >
                Next{" "}
                <img src={RightArrow} alt="Next" className="ml-2" width="16" />
              </Button>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
}
