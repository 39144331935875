import React from "react";
import CustomLeftArrow from "../../../../assets/icons/arrow-circle-left.svg";
import PhoneIcon from "../../../../assets/icons/solar_phone-rounded-broken.svg";
import VehicleIcon from "../../../../assets/icons/ph_car-profile.svg";
import LicensePlateIcon from "../../../../assets/icons/vaadin_password.svg";
import EmailIcon from "../../../../assets/icons/solar_letter-broken.svg";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import PdfViewer from "./PdfViewer";
import { useState } from "react";

interface Driver {
  id: number;
  name: string;
  phone: string;
  email: string;
  vehicleType?: string;
  AccountType?: string;
  CompanyName?: string;
  licensePlateNumber?: string;
}
const documents = [
  { name: "Driving License", file: "path/to/driving_license.pdf" },
  { name: "Towing License", file: "path/to/towing_license.pdf" },
  { name: "Insurance Document", file: "path/to/insurance_document.pdf" },
  {
    name: "Vehicle Registration 1",
    file: "path/to/vehicle_registration_1.pdf",
  },
  {
    name: "Vehicle Registration 2",
    file: "path/to/vehicle_registration_2.pdf",
  },
  {
    name: "Vehicle Registration 3",
    file: "path/to/vehicle_registration_3.pdf",
  },
];

export default function PendingDriver() {
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const driver = location.state?.driver as Driver;

  if (!driver) {
    return <div>No driver data available</div>;
  }

  const handleApprove = () => {
    // Implement approval logic here
    console.log("Driver approved");
    navigate("/drivers");
  };

  const handleReject = () => {
    // Implement rejection logic here
    console.log("Driver rejected");
    navigate("/drivers");
  };

  return (
    <Box
      sx={{
        overflowX: "auto",
        backgroundColor: "white",
        padding: "60px",
        minHeight: "100vh",
        marginLeft: "230px"
      }}
    >
      <Box
        sx={{
          width: "160%",  // Ensures horizontal scroll if content exceeds viewport width
          maxWidth: "1170px",
          marginX: "auto",
          marginTop: "40px",
        }}
      >
      <Link
        to="/admin/drivers"
        style={{
          textDecoration: "none",
          color: "inherit",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src={CustomLeftArrow}
          alt="Back"
          style={{ width: 45, height: 55, marginRight: 10, marginBottom: 10 }}
        />
        <Typography variant="h4" gutterBottom color="#2C3E50">
          Driver Pending Approval
        </Typography>
        <div className="mt-8 flex justify-end space-x-4"></div>
      </Link>
      <div className="flex items-center mb-6">
        <img
          src={`https://i.pravatar.cc/150?u=${driver.id}`}
          alt={driver.name}
          className="w-20 h-20 rounded-full mr-4"
        />
        <h2 className="text-2xl font-semibold">{driver.name}</h2>

        {/* Wrapper for buttons with ml-auto to push to the right */}
        <div className="ml-auto flex space-x-4">
          <Button variant="success" onClick={handleApprove}>
            Approve Driver
          </Button>
          <Button variant="danger" onClick={handleReject}>
            Reject Driver
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-6 mb-6">
        {/* First Row */}
        <Form.Group>
          <Form.Label>Phone Number</Form.Label>
          <img
            src={PhoneIcon}
            alt="Phone Icon"
            className="absolute left-2 top-10 w-7 h-5"
          />
          <Form.Control type="text" value={driver.phone} readOnly />
        </Form.Group>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <img
            src={EmailIcon}
            alt="Email Icon"
            className="absolute left-2 top-10 w-7 h-5"
          />
          <Form.Control type="email" value={driver.email} readOnly />
        </Form.Group>
        <Form.Group>
          <Form.Label>Vehicle Type</Form.Label>
          <img
            src={VehicleIcon}
            alt="Vehicle Icon"
            className="absolute left-2 top-10 w-7 h-5"
          />
          <Form.Control
            type="text"
            value={driver.vehicleType || "N/A"}
            readOnly
          />
        </Form.Group>

        {/* Second Row */}
        <Form.Group>
          <Form.Label>License Plate Number</Form.Label>
          <img
            src={LicensePlateIcon}
            alt="License Plate Icon"
            className="absolute left-2 top-10 w-7 h-5"
          />
          <Form.Control
            type="text"
            value={driver.licensePlateNumber || "N/A"}
            readOnly
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Account Type</Form.Label>
          <Form.Control
            type="text"
            value={driver.AccountType || "N/A"}
            readOnly
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Company Name</Form.Label>
          <Form.Control
            type="text"
            value={driver.CompanyName || "N/A"}
            readOnly
          />
        </Form.Group>
      </div>

      <div className="mt-6 space-y-4">
          <Typography variant="h6" gutterBottom color="#2C3E50">
            Documents
          </Typography>
          {/* Third Row */}
          <div className="grid grid-cols-3 gap-4">
            {documents.slice(0, 3).map((doc, index) => (
              <div
                key={index}
                className="border p-4 rounded cursor-pointer"
                onClick={() => setSelectedFile(doc.file)}
              >
                <p className="font-medium">{doc.name}</p>
                <p className="text-sm text-gray-500">document.pdf</p>
              </div>
            ))}
          </div>
          {/* Fourth Row */}
          <div className="grid grid-cols-3 gap-4 mt-4">
            {documents.slice(3, 6).map((doc, index) => (
              <div
                key={index}
                className="border p-4 rounded cursor-pointer"
                onClick={() => setSelectedFile(doc.file)}
              >
                <p className="font-medium">{doc.name}</p>
                <p className="text-sm text-gray-500">document.pdf</p>
              </div>
            ))}
          </div>
          {/* PDF Viewer */}
          {
            selectedFile && (
              <div className="mt-6">
                <PdfViewer fileUrl={selectedFile}/>

              </div>
            )
          }
        </div>
    </Box>
    </Box>
  );
}
