import React, { useState } from "react";
import PersonDash from "../../../assets/icons/flowbite_user-remove-outline.svg";
import PhoneIcon from "../../../assets/icons/solar_phone-rounded-broken.svg";
import VehicleIcon from "../../../assets/icons/ph_car-profile.svg";
import LicensePlateIcon from "../../../assets/icons/vaadin_password.svg";
import EmailIcon from "../../../assets/icons/solar_letter-broken.svg";
import ColorIcon from "../../../assets/icons/fluent_color-24-regular.svg";
import StatusIcon from "../../../assets/icons/material-symbols-light_paid-outline.svg";
import LeftArrow from "../../../assets/icons/arrow-left.svg"; // Path to your left arrow icon
import RightArrow from "../../../assets/icons/arrow-right.svg";
import { Form, Button, Modal, Row, Col } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";

// Example driver data
const drivers: Driver[] = [
  {
    id: 1,
    name: "Baraka M.Jaa",
    phone: "+255 754 805 256",
    email: "emailaddressgfsdf@gmail.com",
    dateJoined: "23-12-2024 4:35 PM",
    vehicleType: "Toyota Yaris",
    vehicleColor: "Blue",
    licensePlateNumber: "1234AB",
    subscriptionStatus: "ACTIVE",
    subscriptionStartDate: "12 AUG 2024",
    subscriptionEndDate: "12 SEP 2025",
  },
  {
    id: 2,
    name: "John",
    phone: "+255 754 805 256",
    email: "emailaddres@gmail.com",
    dateJoined: "23-12-2024 4:35 PM",
    vehicleType: "Toyota Yaris",
    vehicleColor: "Blue",
    licensePlateNumber: "1234AB",
    subscriptionStatus: "ACTIVE",
    subscriptionStartDate: "12 AUG 2024",
    subscriptionEndDate: "12 SEP 2025",
  },
  {
    id: 3,
    name: "Salma Godwin",
    phone: "+255 754 805 256",
    email: "emailaddresdf@gmail.com",
    dateJoined: "23-12-2024 4:35 PM",
    vehicleType: "Toyota Yaris",
    vehicleColor: "Blue",
    licensePlateNumber: "1234AB",
    subscriptionStatus: "ACTIVE",
    subscriptionStartDate: "12 AUG 2024",
    subscriptionEndDate: "12 SEP 2025",
  },
  {
    id: 4,
    name: "Alice Smith",
    phone: "+255 754 805 256",
    email: "alice@example.com",
    dateJoined: "23-12-2024 4:35 PM",
    vehicleType: "Toyota Yaris",
    vehicleColor: "Blue",
    licensePlateNumber: "1234AB",
    subscriptionStatus: "ACTIVE",
    subscriptionStartDate: "12 AUG 2024",
    subscriptionEndDate: "12 SEP 2025",
  },
  {
    id: 5,
    name: "Bob Johnson",
    phone: "+255 754 805 256",
    email: "bob@example.com",
    dateJoined: "23-12-2024 4:35 PM",
    vehicleType: "Toyota Yaris",
    vehicleColor: "Blue",
    licensePlateNumber: "1234AB",
    subscriptionStatus: "ACTIVE",
    subscriptionStartDate: "12 AUG 2024",
    subscriptionEndDate: "12 SEP 2025",
  },
  {
    id: 6,
    name: "Charlie Brown",
    phone: "+255 754 805 256",
    email: "charlie@example.com",
    dateJoined: "23-12-2024 4:35 PM",
    vehicleType: "Toyota Yaris",
    vehicleColor: "Blue",
    licensePlateNumber: "1234AB",
    subscriptionStatus: "ACTIVE",
    subscriptionStartDate: "12 AUG 2024",
    subscriptionEndDate: "12 SEP 2025",
  },
  {
    id: 7,
    name: "David Wilson",
    phone: "+255 754 805 256",
    email: "david@example.com",
    dateJoined: "23-12-2024 4:35 PM",
    vehicleType: "Toyota Yaris",
    vehicleColor: "Blue",
    licensePlateNumber: "1234AB",
    subscriptionStatus: "ACTIVE",
    subscriptionStartDate: "12 AUG 2024",
    subscriptionEndDate: "12 SEP 2025",
  },
  {
    id: 8,
    name: "Eva Green",
    phone: "+255 754 805 256",
    email: "eva@example.com",
    dateJoined: "23-12-2024 4:35 PM",
    vehicleType: "Toyota Yaris",
    vehicleColor: "Blue",
    licensePlateNumber: "1234AB",
    subscriptionStatus: "ACTIVE",
    subscriptionStartDate: "12 AUG 2024",
    subscriptionEndDate: "12 SEP 2025",
  },
  {
    id: 9,
    name: "Frank Knight",
    phone: "+255 754 805 256",
    email: "frank@example.com",
    dateJoined: "23-12-2024 4:35 PM",
    vehicleType: "Toyota Yaris",
    vehicleColor: "Blue",
    licensePlateNumber: "1234AB",
    subscriptionStatus: "ACTIVE",
    subscriptionStartDate: "12 AUG 2024",
    subscriptionEndDate: "12 SEP 2025",
  },
  {
    id: 10,
    name: "Grace Lee",
    phone: "+255 754 805 256",
    email: "grace@example.com",
    dateJoined: "23-12-2024 4:35 PM",
    vehicleType: "Toyota Yaris",
    vehicleColor: "Blue",
    licensePlateNumber: "1234AB",
    subscriptionStatus: "ACTIVE",
    subscriptionStartDate: "12 AUG 2024",
    subscriptionEndDate: "12 SEP 2025",
  },
];

interface Driver {
  id: number;
  name: string;
  phone: string;
  email: string;
  dateJoined: string;
  vehicleType?: string; // Added optional fields for the modal
  vehicleColor?: string;
  licensePlateNumber?: string;
  subscriptionStatus?: string;
  subscriptionStartDate?: string;
  subscriptionEndDate?: string;
}

const UserComponent: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<Driver | null>(null);
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Filter drivers based on search query
  const filteredDrivers = drivers.filter(
    (driver) =>
      driver.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      driver.phone.toLowerCase().includes(searchQuery.toLowerCase()) ||
      driver.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div
      className="p-6 bg-white min-h-screen"
      style={{ maxWidth: "90%", marginLeft: "240px", marginTop: "100px" }}
    >
       <Box sx={{ p: 3 }}>
        <div className="flex justify-between items-center mb-4">
          <Typography variant="h4" color="#2C3E50">
            Users
          </Typography>
          <div className="relative">
            <Search
              className="absolute left-4 top-1/2 transform -translate-y-1/2"
              size={20}
              style={{ color: "#FDA214"}} // Change icon color
            />
            <Form.Control
              type="text"
              placeholder="Search Users by Name / Email / Phone No."
              className="pl-10 pr-6 py-2 w-112 rounded-full border border-gray-300"
              value={searchQuery}
              onChange={handleSearch}
              style={{
                width: "30rem",
                height: "3rem",
                lineHeight: "3rem",
                borderRadius: "20px",
                textAlign: "center",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                border: "1px solid #d3d3d3",
              }} // Custom width and height// Additional rounding if needed
            />
          </div>
            </div>
        <TableContainer component={Paper} elevation={0}>
          <Table>
            <TableHead sx={{backgroundColor: '#F0F3FA'}}>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>User Name</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} >Phone no.</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} >Email</TableCell>
                <TableCell sx={{ padding: "30px" ,fontWeight: 'bold' }}>
                  Date Joined <KeyboardArrowDown />
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} >Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredDrivers.map((driver) => (
                <TableRow
                  key={driver.id}
                  onClick={() => {
                    setSelectedUser(driver);
                    setShowModal(true);
                  }}
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      hoveredRow === driver.id ? "#f0f0f0" : "transparent",
                  }}
                  onMouseEnter={() => setHoveredRow(driver.id)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  <TableCell>{driver.name}</TableCell>
                  <TableCell>{driver.phone}</TableCell>
                  <TableCell>{driver.email}</TableCell>
                  <TableCell>{driver.dateJoined}</TableCell>
                  <TableCell>
                    <Button
                      variant="link"
                      className="text-gray-500 hover:text-gray-700 p-1"
                      onClick={() => {
                        setSelectedUser(driver);
                        setShowModal(true);
                      }}
                    >
                      <img
                        src={PersonDash}
                        alt="Person Icon"
                        width="30"
                        height="30"
                        className="ml-2"
                      />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          className="d-flex justify-content-center  align-items-center mt-4 flex-wrap p-3 rounded-lg"
          style={{ height: "100px" }}
        >
          <div className="d-flex justify-content-center mt-auto flex-wrap">
            {/* Previous Button with Custom Left Arrow */}
            <Button
              variant={currentPage > 1 ? "outline-secondary" : "disabled"}
              size="sm"
              className="mx-1 d-flex align-items-center justify-content-center"
              onClick={() =>
                currentPage > 1 && handlePageChange(currentPage - 1)
              }
              style={{
                backgroundColor: currentPage > 1 ? "#FDA214" : "transparent",
                color: currentPage > 1 ? "#fff" : "#6c757d",
                borderColor: currentPage > 1 ? "#FDA214" : "#6c757d",
              }}
            >
              <img src={LeftArrow} alt="Previous" className="mr-2" width="16" />{" "}
              Previous
            </Button>

            {/* Page Numbers */}
            {[1, 2, 3, "...", 8, 9, 10].map((page, index) => (
              <Button
                key={index}
                variant={currentPage === page ? "primary" : "outline-secondary"}
                size="sm"
                onClick={() =>
                  typeof page === "number" && handlePageChange(page)
                }
                className={`mx-1 ${page === "..." ? "cursor-default" : ""}`}
                style={{
                  backgroundColor:
                    currentPage === page ? "#FDA214" : "transparent",
                  color: currentPage === page ? "#fff" : "#FDA214",
                  borderColor: currentPage === page ? "#FDA214" : "#FDA214",
                }}
                disabled={page === "..."}
              >
                {page}
              </Button>
            ))}

            {/* Next Button with Custom Right Arrow */}
            <Button
              variant={currentPage < 10 ? "outline-secondary" : "disabled"}
              size="sm"
              className="mx-1 d-flex align-items-center justify-content-center"
              onClick={() =>
                currentPage < 10 && handlePageChange(currentPage + 1)
              }
              style={{
                backgroundColor: currentPage < 10 ? "#FDA214" : "transparent",
                color: currentPage < 10 ? "#fff" : "#6c757d",
                borderColor: currentPage < 10 ? "#FDA214" : "#6c757d",
              }}
            >
              Next{" "}
              <img src={RightArrow} alt="Next" className="ml-2" width="16" />
            </Button>
          </div>
        </div>
        {selectedUser && (
          <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <Modal.Header className="border-0 pb-0">
              <Modal.Title className="w-100 text-left font-bold text-xl">
                USER DETAILS
              </Modal.Title>
              <Button
                variant="link"
                onClick={handleCloseModal}
                className="absolute top-2 right-2"
              ></Button>
            </Modal.Header>
            <Modal.Body className="pt-0">
              <div className="flex items-center mb-4">
                <div className="w-20 h-20 rounded-full bg-gray-200 mr-4 overflow-hidden">
                  {/* <img
                  src="/placeholder.svg?height=80&width=80"
                  alt={user.name}
                  className="w-full h-full object-cover"
                /> */}
                </div>
                <h2 className="text-2xl font-semibold">{selectedUser.name}</h2>
              </div>
              <Form>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-4 relative">
                      <Form.Label>Phone Number</Form.Label>
                      <img
                        src={PhoneIcon}
                        alt="Phone Icon"
                        className="absolute left-2 top-10 w-7 h-5"
                      />
                      <Form.Control
                        type="tel"
                        value={selectedUser.phone}
                        readOnly
                        style={{ paddingLeft: "2.5rem" }} // Add left padding to make space for the icon
                      />
                    </Form.Group>
                    <Form.Group className="mb-4 relative">
                      <Form.Label>Vehicle Type</Form.Label>
                      <img
                        src={VehicleIcon}
                        alt="Vehicle Icon"
                        className="absolute left-2 top-10 w-7 h-5"
                      />
                      <Form.Control
                        type="text"
                        value={selectedUser.vehicleType || ""}
                        readOnly
                        style={{ paddingLeft: "2.5rem" }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-4 relative">
                      <Form.Label>License Plate Number</Form.Label>
                      <img
                        src={LicensePlateIcon}
                        alt="License Plate Icon"
                        className="absolute left-2 top-10 w-7 h-5"
                      />
                      <Form.Control
                        type="text"
                        value={selectedUser.licensePlateNumber || ""}
                        readOnly
                        style={{ paddingLeft: "2.5rem" }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-4 relative">
                      <Form.Label>Subscription Start Date</Form.Label>
                      <Form.Control
                        type="text"
                        value={selectedUser.subscriptionStartDate || ""}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-4 relative">
                      <Form.Label>Email</Form.Label>
                      <img
                        src={EmailIcon}
                        alt="Email Icon"
                        className="absolute left-2 top-10 w-7 h-5"
                      />
                      <Form.Control
                        type="email"
                        value={selectedUser.email}
                        readOnly
                        style={{ paddingLeft: "2.5rem" }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-4 relative">
                      <Form.Label>Vehicle Color</Form.Label>
                      <img
                        src={ColorIcon}
                        alt="Color Icon"
                        className="absolute left-2 top-10 w-7 h-5"
                      />
                      <Form.Control
                        type="text"
                        value={selectedUser.vehicleColor || ""}
                        readOnly
                        style={{ paddingLeft: "2.5rem" }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-4 relative">
                      <Form.Label>Subscription Status</Form.Label>
                      <img
                        src={StatusIcon}
                        alt="Status Icon"
                        className="absolute left-2 top-10 w-7 h-5"
                      />
                      <Form.Control
                        type="text"
                        value={selectedUser.subscriptionStatus || ""}
                        readOnly
                        className={` ${
                          selectedUser.subscriptionStatus === "ACTIVE"
                            ? "text-green-600 font-semibold"
                            : ""
                        }`}
                        style={{ paddingLeft: "2.5rem" }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-4 relative">
                      <Form.Label>Subscription End Date</Form.Label>
                      <Form.Control
                        type="text"
                        value={selectedUser.subscriptionEndDate || ""}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer className="border-0 justify-center">
              <div className="w-full flex justify-center gap-4">
                <Button
                  variant="danger"
                  className="px-6 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
                >
                  Remove User
                </Button>
                <Button
                  variant="secondary"
                  onClick={handleCloseModal}
                  className="px-6 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition-colors"
                >
                  Close
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        )}
        
      </Box>
    </div>
  );
};

export default UserComponent;
