
import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

// Define the props type
interface PdfViewerProps {
  fileUrl: string; // Specify the type for fileUrl
}

const PdfViewer: React.FC<PdfViewerProps> = ({ fileUrl }) => (
  <div style={{ height: '750px', border: '1px solid #e4e4e4' }}>
    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
      <Viewer fileUrl={fileUrl} />
    </Worker>
  </div>
);

export default PdfViewer;
