import React from 'react';
import { 
  Grid, 
  Card, 
  CardContent, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper 
} from '@mui/material';

import RegisterdUser from "../../../assets/icons/user.svg";
import SubUser from "../../../assets/icons/Approved.svg";
import Tickets from "../../../assets/icons/help.svg";
import ApprovedDriver from "../../../assets/icons/driverApp.svg";
import PendingDriver from "../../../assets/icons/person.svg";
import DistanceCovered from "../../../assets/icons/direction.svg";
import Revenue from "../../../assets/icons/dollar.svg";
import Journeys from "../../../assets/icons/distance.svg";
import Distance from "../../../assets/icons/car.svg";


interface StatCardProps {
  title: string;
  value: string;
  icon: string;
}

const StatCard: React.FC<StatCardProps> = ({ title, value, icon }) => {
 
  // condtionaly render color value of card 
  let valueColor ="#345C72";
  if (title === "Total Revenue from Users") {
    valueColor = "#1FA541"; 
  } else if (title === "Pending Support Tickets") {
    valueColor = "#C63708"; 
  }

  return(
  <Card sx={{ backgroundColor: '#F0F3FA' }}>
    <CardContent>
    <div style={{ float: 'right' }}>
        <img src={icon} alt={title} style={{ width: '30px', height: '30px', marginBottom: '10px' }} /> {/* Use img tag for icon */}
      </div>
      <Typography variant="h6" fontWeight="medium" color="#345C72" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h6" fontWeight="bold"  color={valueColor} component="div">
        {value}
      </Typography>
    
    </CardContent>
  </Card>
);
}


const Dashboard: React.FC = () => {
  return (
    <div
    className="p-6 bg-white min-h-screen"
    style={{ maxWidth: "80%", marginLeft: "300px", marginTop: "140px" }}
  >
    <Typography variant="h4" gutterBottom color="#2C3E50">
      Dashboard
    </Typography>

    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={4}>
        <StatCard title="Registered Users" value="5000" icon={RegisterdUser} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StatCard title="Subscribed Users" value="200" icon={SubUser} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StatCard title="Pending Support Tickets" value="200" icon={Tickets} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StatCard title="Approved Drivers" value="5000" icon={ApprovedDriver} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StatCard title="Pending Driver Accounts" value="200" icon={PendingDriver} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StatCard title="Total Distance Covered" value="54,500 KM" icon={DistanceCovered} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} >
        <StatCard title="Total Revenue from Users" value="$5000" icon={Revenue} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StatCard title="Total Journeys" value="200" icon={Journeys} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StatCard title="Average Trip Distance" value="200 KM" icon={Distance} />
      </Grid>
    </Grid>
      

      <Typography variant="h5"  color='#2C3E50' gutterBottom style={{ marginTop: '24px' }}>
        Subscription Summary
      </Typography>
      <TableContainer component={Paper}  elevation={0} color='#2C3E50'>
        <Table>
          <TableHead sx={{backgroundColor: '#F0F3FA'}}>
            <TableRow color='#2C3E50'>
              <TableCell sx={{ fontWeight: 'bold' }} >Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Plan</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Start Date</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>End Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Bhavya M Jain</TableCell>
              <TableCell>bhavyamjain97@gmail.com</TableCell>
              <TableCell>Plan 1</TableCell>
              <TableCell>21-12-2021</TableCell>
              <TableCell>21-12-2024</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tom</TableCell>
              <TableCell>tom@example.com</TableCell>
              <TableCell>Plan 2</TableCell>
              <TableCell>21-12-2021</TableCell>
              <TableCell>21-12-2024</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Sammy Graham</TableCell>
              <TableCell>sammy@example.com</TableCell>
              <TableCell>Plan 3</TableCell>
              <TableCell>21-12-2021</TableCell>
              <TableCell>21-12-2024</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Abdul</TableCell>
              <TableCell>abdul@example.com</TableCell>
              <TableCell>Plan 4</TableCell>
              <TableCell>21-12-2021</TableCell>
              <TableCell>21-12-2024</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Joshua Mark</TableCell>
              <TableCell>joshua@example.com</TableCell>
              <TableCell>Plan 1</TableCell>
              <TableCell>21-12-2021</TableCell>
              <TableCell>21-12-2024</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h5" color='#2C3E50' gutterBottom style={{ marginTop: '24px' }}>
        Top Performing Drivers
      </Typography>
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead sx={{backgroundColor: '#F0F3FA'}}>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }} color='#2C3E50' >Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} color='#2C3E50' >Completed Rides</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} color='#2C3E50' >Earnings ($)</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} color='#2C3E50' >Distance Covered (km)</TableCell>
              <TableCell  sx={{ fontWeight: 'bold' }} color='#2C3E50' >Active Days</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow color='#2C3E50'>
              <TableCell>Bhavya M Jain</TableCell>
              <TableCell>120</TableCell>
              <TableCell>2000</TableCell>
              <TableCell>1500 km</TableCell>
              <TableCell>22</TableCell>
            </TableRow>
            <TableRow color='#2C3E50'>
              <TableCell>Abdul</TableCell>
              <TableCell>100</TableCell>
              <TableCell>1800</TableCell>
              <TableCell>1400 km</TableCell>
              <TableCell>20</TableCell>
            </TableRow>
            <TableRow color='#2C3E50'>
              <TableCell>Sammy Graham</TableCell>
              <TableCell>110</TableCell>
              <TableCell>1900</TableCell>
              <TableCell>1450 km</TableCell>
              <TableCell>21</TableCell>
            </TableRow>
            <TableRow color='#2C3E50'>
              <TableCell>Abdul</TableCell>
              <TableCell>90</TableCell>
              <TableCell>1700</TableCell>
              <TableCell>1350 km</TableCell>
              <TableCell>19</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Dashboard;
