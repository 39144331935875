import React from 'react';
import AdminIcon from "../../assets/icons/Vector.svg"; // Ensure this icon is white


interface HeaderProps {
  adminName: string;
}

const Header: React.FC<HeaderProps> = ({ adminName }) => {
  return (
    <header style={{ backgroundColor: "#FDA214",}}className=" text-white p-2 flex justify-between items-center fixed top-0 left-0 right-0 z-20"> {/* Set position to fixed */}
      <div className="flex items-center">
        {/* Admin Photo with a colored background */}
        <div className="bg-gray-50 rounded-full p-1 mr-3"> {/* Add a gray background */}
          <img 
            src={AdminIcon}
            alt="Admin Profile"
            className="w-10 h-10 rounded-full"
          />
        </div>
        <div>
          <p className="font-bold text-blue-950">HELLO, {adminName.toUpperCase()}</p>
          <div className="flex items-center">
            <span className="bg-green-500 rounded-full mb-3 w-2.5 h-2.5 ml-2"></span> {/* Green Dot */}
            <p className="text-xs text-blue-950 ml-2">Active now</p>
          </div>
        </div>
      </div>
      <h1 className="text-2xl font-bold text-center"> {/* Centered text */}
        MARQUEL WRECKING ADMIN PANEL
      </h1>
    </header>
  );
};

export default Header;
